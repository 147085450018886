import { useState }                 from "react";
import React                        from "react";
import { Field }                    from "react-final-form";
import { Form }                     from "react-final-form";
import api                          from "../../../Api";
import { useLazyAsync }             from "../../../hooks/useAsync";
import { useWizard }                from "../../../hooks/useWizard";
import { Button }                   from "../../Button";
import CountrySelectorWithDetection from "../../CountrySelectorWithDetection/CountrySelectorWithDetection";
import { GoToPrevPage }             from "../../GoToPrevPage";
import { Header }                   from "../../Header";
import countries                    from "../../CountrySelectorWithDetection/countries.json";
import { useParams }                from "react-router-dom";

const Verifying = () => {
  const { id } = useParams();
  const [_, next] = useWizard();
  const [call] = useLazyAsync((data) => api.verify(data), []);
  const [selectedCountry, setSelectedCountry] = useState<any>({
    "name": "United States",
    "dialCode": "+1",
    "isoCode": "US",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/us.svg"
  });
  const [mobileNumber, setMobileNumber] = useState("");

  const handleCountrySelect = (country: any) => {
    setMobileNumber(mobileNumber);
    setSelectedCountry(country);
  };
  const onSubmit = async (values: any) => {
    let data = await call({
      productId: id,
      phoneNumber: `${selectedCountry.dialCode}${values.phoneNumber}`
    });
    if (!data) {
      return { phoneNumber: "Invalid phone number" };
    }
    if (data.verified) {
      if (data.procedure?.id) {
        next("feed-back", data);
      } else {
        if (data.name) {
          next("congrats", data);
        } else {
          next("greeting", data);
        }
      }
    } else {
      next("verification-code", data);
    }
  };
  return (
    <main className="w-content">
      <GoToPrevPage/>
      <div className="flex flex-col justify-center items-center overflow-hidden flex-grow-1 h-0
                               max-sm: h-71">
        <img src="/keppy_verify.webp?v=20241019" height={"100%"} alt="Keppy men" className="h-[inherit] content-img"/>
      </div>
      <Header title="1st step 🔎"
              description="Enter your phone number to verify yourself and confirm that you are not a robot "/>

      <Form<FormData>
        onSubmit={onSubmit}
        validate={(values: any) => {
          const errors: any = {};
          if (!values.phoneNumber) {
            errors.phoneNumber = "Please fill in the phone number";
          }
          return errors;
        }}
        render={({ handleSubmit, submitting }: any) => (
          <form onSubmit={handleSubmit} className={"flex flex-col gap-2"}>
            <CountrySelectorWithDetection countries={countries} onSelect={handleCountrySelect}/>
            <Field name={"agree"}>
              {({ input }) => (
                <div className="py-4 flex text-red-primary max-sm: py-0">
                  <div>
                    <div
                      className="  border-blue-primary   h-[24px] w-[24px] border-2 border-solid border-rounded  rounded-lg float-left 	mr-3 flex items-center justify-center"
                      onClick={() => input.onChange(!input.value)}
                    >
                      {!!input.value &&
                      <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1 4L5.24515 7.39612C5.66612 7.73289 6.27826 7.67536 6.6291 7.26604L12 1"
                          stroke="#5000FF"
                          strokeWidth="2" strokeLinecap="round"/>
                      </svg>
                      }
                    </div>
                  </div>
                  <span className="text-base" style={{ fontSize: 11 }}>
                  <span className={" text-black"} style={{ color: "black" }}>I am aware that the provided phone number will be used for post-procedure communication.</span>
                  <div className={"mt-1  text-black"} style={{ color: "black" }}>I understand that I may receive messages from the current dental practice.</div>
                  <div className={"mt-1  text-black"} style={{ color: "black" }}>SMS messaging fees may apply and you can respond with "STOP" at any time to prevent further contact.</div>
              </span>
                </div>
              )}
            </Field>
            <Field name={"agree"} subscription={{ value: true }}>
              {({ input }) => <Button disabled={submitting || !input.value}
                                      text="Send code" type="submit"
                                      className={`${submitting || !input.value? "bg-gray-primary" : "bg-blue-primary"}`}
                                     />
              }
            </Field>

          </form>
        )}
      />
    </main>
  );
};

export default Verifying;