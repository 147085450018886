import React              from "react";

const LogoPage = () => {

  return (
    <main className="w-content">
      <div className="flex flex-col justify-center items-center h-screen">
        <img src="/Keppy.svg?v=20241019" alt="Keppy"/>
      </div>
    </main>
  );
};

export default LogoPage;