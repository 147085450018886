import React, {useCallback, useEffect, useMemo} from "react";
import {Header} from "../../DoctorHeader/Header";
import {Table} from "../../Table";
import {Form, Field, FormSpy} from "react-final-form";
import {FORM_ERROR, FormApi} from "final-form";
import api from "../../../Api";
import {VIDEOS_PATH} from "../../../NetService/doctorConstants";
import {useSnackbar} from "notistack";
import Button from '@mui/material/Button';
import {useDoctorWizard} from "../../../hooks/doctorUseWizard";
import TextField from "@mui/material/TextField";
import {Select} from "mui-rff";
import {MenuItem} from "@mui/material";
import {TableTest} from "../../DoctorTable";


interface VideoData {
    age: ("3-5" | "6-12" | "13-18" | "19-35" | "36+")[]; // Define possible age categories
    category: "Cartoon" | "Nature" | "360VR" | "Game" | "Other"; // Define possible categories
    channelTitle: string;
    createdAt: string;
    id: string;
    image: string;
    title: string;
    updatedAt: string;
    url: string;
    youtubeId: string
}

type FormValues = {
    url: string,
    title: string,
    age: string,
    category: string,
}
export const ContentList = React.memo(() => {
        const [data, setData] = React.useState<VideoData[]>([]);
        const [edit, setEdit] = React.useState<VideoData | undefined>();
        const [next, State] = useDoctorWizard();
        const {enqueueSnackbar} = useSnackbar();

        // const videoDataPreprocessing = (data) => {
        //     console.log(data)
        //     const processed = data.map((item) => {
        //
        //             return {
        //                 ...(item.user && { user: item.user }),
        //                 age: item.age,
        //                 image: item.image,
        //                 name: item.title || item.channelTitle,
        //                 url: item.url,
        //                 category: item.category,
        //                 updatedAt: item.updatedAt,
        //
        //
        //             }
        //
        //             }
        //     )
        //     console.log(processed)
        //     setData(processed);
        //
        // }

        const fetchData = async () => {
            try {
                const result = await api.getApi(`${VIDEOS_PATH}?sort={"createdAt":-1}`);
                setData(result.results);
                // videoDataPreprocessing(result.results);
            } catch (error) {
                console.error("Error", error);
            }
        };
        useEffect(() => {
            fetchData()
        }, []);


        const handelEdit = useCallback((edit: any) => {
            window.scrollTo({top: 0, behavior: "smooth"});
            setEdit(edit);
        }, []);

        let videosCount = useMemo(() => {
            let count = {
                "3-5": {"Cartoon": 0, "Nature": 0, "360VR": 0, "Game": 0, "Other": 0},
                "6-12": {"Cartoon": 0, "Nature": 0, "360VR": 0, "Game": 0, "Other": 0},
                "13-18": {"Cartoon": 0, "Nature": 0, "360VR": 0, "Game": 0, "Other": 0},
                "19-35": {"Cartoon": 0, "Nature": 0, "360VR": 0, "Game": 0, "Other": 0},
                "36+": {"Cartoon": 0, "Nature": 0, "360VR": 0, "Game": 0, "Other": 0}
            };
            if (data.length) {
                data.forEach((item: VideoData) => {
                    item.age.forEach((age) => {
                        count[age][item.category] = count[age][item.category] + 1;
                    });
                });
            }

            return count;
        }, [data]);


        const validation = (values: any) => {
            //Hayk es custom validation@ poxaren porci fielderin required option uxarkes vonc mer adminkuma arac
            const error: any = {}
            if (!values.url) {
                throw new Error("URL is required");
            }
            if (!values.category) {
                error.category = 'category required';
                throw new Error("Category is required");
            }
            if (!values.age) {
                throw new Error("Age is required");
            }
        };

        const onSubmit = async (value: any, form) => {
            const data = {
                "category": value.category,
                "url": value.url,
                "age": value.age,
                "title": value.title
            };
            if (value.id) {
                try {
                    validation(data);
                    const url = VIDEOS_PATH + "/" + `${value.id}`;
                    await api.putApi(url, data);
                    form.reset()
                    enqueueSnackbar("Success", {variant: "success"});
                    setEdit(undefined);

                } catch (e) {
                    enqueueSnackbar("Video already added ", {variant: "error"}) // need to change
                    if (e instanceof Error) {

                        enqueueSnackbar(e.message, {variant: "error"});

                    }
                    return {[FORM_ERROR]: "An unexpected error occurred. Please try again."};
                }
            } else {
                try {
                    validation(data);
                    await api.postApi(VIDEOS_PATH, data);
                    setEdit(undefined)
                    form.reset()
                    enqueueSnackbar("Success", {variant: "success"});
                    ;
                } catch (e) {
                    enqueueSnackbar("Video already added ", {variant: "error"})
                    if (e instanceof Error) {
                        enqueueSnackbar(e.message, {variant: "error"});
                    }
                    return {[FORM_ERROR]: "An unexpected error occurred. Please try again."};
                }
            }
            fetchData()

        };


        return (
            <div className={"ml-[288px] w-full h-screen"}>
                <Header className={"pt-5 ml-5"} text={"Products"}/>
                <div>
                    <TableTest rows={[
                        {
                            registered: State?.subscription?.createdAt,
                            productType: State?.subscription?.productType,
                            subscription: State?.subscription?.subscription,
                            key: State?.key,
                            expired: State?.subscription?.expiredAt,
                            days: State?.subscription?.expiredDays,
                            firstName: State?.user?.firstName,
                            lastName: State?.user?.lastName,
                            phoneNumber: State?.user?.phoneNumber,
                            email: State?.user?.email
                        }
                    ]}
                               columns={["Registered", "Product Type", "Subscription", "Key", "Expired", "Days", "First Name", "Last  Name", "Phone Number", "Email"]}
                               tdClass={'pr-2 text-start text-base'}
                               thClass={'text-start text-gray-light font-normal pr-2 '}/>
                    {/*<Table*/}
                    {/*    name={"communication"}*/}
                    {/*    tableName={["Registered", "Product Type", "Subscription", "Key", "Expired", "Days", "First Name", "Last  Name", "Phone Number", "Email"]}*/}
                    {/*    data={[*/}
                    {/*        {*/}
                    {/*            registered: State?.subscription?.createdAt,*/}
                    {/*            productType: State?.subscription?.productType,*/}
                    {/*            subscription: State?.subscription?.subscription,*/}
                    {/*            key: State?.key,*/}
                    {/*            expired: State?.subscription?.expiredAt,*/}
                    {/*            days: State?.subscription?.expiredDays,*/}
                    {/*            firstName: State?.user?.firstName,*/}
                    {/*            lastName: State?.user?.lastName,*/}
                    {/*            phoneNumber: State?.user?.phoneNumber,*/}
                    {/*            email: State?.user?.email*/}
                    {/*        }*/}
                    {/*    ]}/>*/}
                </div>
                <div>
                    <div className={"mt-4  border-b-2"}>
                        <Form onSubmit={onSubmit}
                              initialValues={edit && Object.keys(edit).length > 0 ? edit : {
                                  url: "",
                                  name: "",
                                  category: "",
                                  age: "",
                                  title:""
                              }}

                              render={({handleSubmit, form}) => (
                                  <>

                                      <form onSubmit={handleSubmit}>
                                          <div className={"w-full p-4  "}>
                                              <div className={"w-full  gap-2"}>
                                                  <div
                                                      className="flex flex-wrap gap-4 w-full items-center max-lg:flex max-lg:flex-col max-lg:items-start">
                                                      <div className="flex-1 flex flex-col gap-2 w-1/3">
                                                          <span className={"font-extrabold text-blue-dark truncate"}>Copy YouTube link here to add patient content</span>
                                                          <Field
                                                              name="url"
                                                              component="input"
                                                              subscription={{ value: true, touched: true, error: true, dirty: true }}
                                                              initialValue="" // Add an initial value
                                                              parse={(value) => {
                                                                  form.change("title", undefined);
                                                                  return value;
                                                              }}
                                                              validate={(value) => {
                                                                  const urlRegex = /^(https?:\/\/)?([\w\-]+\.)+[a-z]{2,6}(:\d+)?(\/[\w\-_.~+%]*)*(\?[\w\-_.~+=&%]*)?(#[\w\-_.~+=&%]*)?$/i;
                                                                  if (!urlRegex.test(value)) {
                                                                      return "URL is invalid";
                                                                  }
                                                                  return undefined;
                                                              }}
                                                          >
                                                              {({input, meta}) => (
                                                                  <TextField label="URL" variant="outlined"
                                                                             {...input}
                                                                             required={true}
                                                                             className={'w-full'}
                                                                             error={meta.error && meta.touched && meta.dirty}


                                                                             sx={{
                                                                                 '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                                     borderColor: '#5000FF',
                                                                                 },
                                                                                 '& .MuiInputLabel-root.Mui-focused': {
                                                                                     color: '#5000FF',
                                                                                 }
                                                                             }}/>
                                                              )}
                                                          </Field>
                                                          <Field name="title" component="input">
                                                              {({input, meta}) => (
                                                                  <TextField label="Name" variant="outlined"
                                                                             {...input}
                                                                             className={'w-full'}
                                                                             sx={{
                                                                                 '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                                     borderColor: '#5000FF',
                                                                                 },
                                                                                 '& .MuiInputLabel-root.Mui-focused': {
                                                                                     color: '#5000FF',
                                                                                 }
                                                                             }}/>
                                                              )}
                                                          </Field>
                                                      </div>
                                                      <div className={'flex w-1/3 gap-2'}>
                                                          <Select
                                                              name="category"
                                                              label="Category"
                                                              labelId={"category"}
                                                              required={true}
                                                              sx={{
                                                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                      borderColor: '#5000FF',
                                                                  },
                                                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                      borderColor: '#5000FF',

                                                                  },
                                                                  '& .MuiInputLabel-root.Mui-focused': {
                                                                      color: '#5000FF',
                                                                  },

                                                              }}
                                                          >
                                                              <MenuItem value="Cartoon">Cartoon</MenuItem>
                                                              <MenuItem value="Nature">Nature</MenuItem>
                                                              <MenuItem value="360VR">360VR</MenuItem>
                                                              <MenuItem value="Game">Game</MenuItem>
                                                              <MenuItem value="Other">Other</MenuItem>
                                                          </Select>


                                                          <Select
                                                              multiple={true}
                                                              name="age"
                                                              label="Age range"
                                                              labelId={"age"}
                                                              required={true}
                                                              sx={{
                                                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                      borderColor: '#5000FF',
                                                                  },
                                                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                      borderColor: '#5000FF',

                                                                  },
                                                                  '& .MuiInputLabel-root.Mui-focused': {
                                                                      color: '#5000FF',
                                                                  },

                                                              }}
                                                          >
                                                              <MenuItem value="3-5">3-5</MenuItem>
                                                              <MenuItem value="6-12">6-12</MenuItem>
                                                              <MenuItem value="13-18">13-18</MenuItem>
                                                              <MenuItem value="19-35">19-35</MenuItem>
                                                              <MenuItem value="36+">36+</MenuItem>
                                                          </Select>


                                                      </div>


                                                      <div
                                                          className="flex flex-1 gap-4 ml-auto max-lg:flex  max-lg:items-start max-lg:m-0 max-lg:w-[50%]">
                                                          <Button variant="outlined" onClick={() => {
                                                              form.reset();
                                                              setEdit(undefined);
                                                          }}
                                                                  sx={{
                                                                      width: '100%',
                                                                      color: '#5000FF',
                                                                      borderColor: '#5000FF',
                                                                      paddingY: '14px'
                                                                  }}>Cancel</Button>
                                                          <FormSpy subscription={{dirty: true}}>
                                                              {({dirty}) => (
                                                                  <Button disabled={!dirty} type={'submit'} variant="contained" sx={{
                                                                      color: 'white',
                                                                      width: '100%',
                                                                      backgroundColor: '#5000FF',
                                                                      '&:disabled': {
                                                                          color: '#191919',
                                                                      },
                                                                  }}>Save</Button>
                                                              )}
                                                          </FormSpy>
                                                      </div>
                                                  </div>


                                              </div>
                                          </div>
                                          <div className={"absolute right-4 bottom-4"}>

                                          </div>
                                      </form>
                                  </>
                              )}
                        />
                    </div>
                    {/*age?: string,*/}
                    {/*image?: string,*/}
                    {/*name?: string,*/}
                    {/*url?: string,*/}
                    {/*category?: string,*/}
                    {/*updatedAt?: string,*/}
                    {data?.length > 0 && (
                        <div className={"flex w-full max-2xl:flex-col max-2xl:gap-9"}>
                            <div className="max-2xl:w-[100%]  ">
                                <Table name={"videoData"} videoStatistics={videosCount}/>
                            </div>
                            {/*<TableTest rows={data} columns={['Age range','Video', "Name","Link","Category","Added"]} edit={true}/>*/}
                            <Table name={"video"} videoInfo={data} edit={handelEdit}/>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);
