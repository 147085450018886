import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {EditModal} from "../DoctorModal/EditModal";




interface RowProps {

    registered?: string
    productType?: string
    subscription?: number
    key?: string
    expired?: string
    days?: number
    firstName?: string
    lastName?: string
    phoneNumber?: string
    email?: string
    sms?: string
    status?: string
    id?: string
    updatedAt?: Date

}
interface IProcedur {

    customer:{
        age:string,
        gender:string,
    }


    user:{
        firstName: string,
        lastName: string,
        age?: string,
        gender?: string,
        phoneNumber:string
    }
    product:{
        id: string
    }
    experience: number
    duration:number
    failed:string,
    url: string,
    own:boolean,
    createdAt:string
    followup:boolean,
    same: boolean
    procedureType:string,
    qr?:string,
    procedure:number,
    problem:number,



}

interface VideosProps {
    age: string | string[],
    category: string,
    channelTitle: string
    createdAt: string
    id: string
    image: string
    title: string
    updatedAt: string
    url: string
    youtubeId: string
}

interface VideosState {
    '3-5Y': object;
    '6-12Y': object;
    '13-18Y': object;
    '19-35Y': object;
    '36+': object;
}

interface TableProps {
    tableName?: string[];
    data?: RowProps[],
    videoData?: VideosState[],
    videoInfo?: VideosProps[]
    staData? : IProcedur[]
    name: string
    videoStatistics?: any
    edit?: (obj: object) => void;

}

export interface IEditProps {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    index?: number
    id?: string
    edit?: boolean
}

//todo Hayk ete es Table um et prop key i tak konkret edit functionna misht uxarkvum uremn et properti i anuN@ normal dzi
export const Table = React.memo<TableProps>(({tableName, videoInfo, staData, videoData, videoStatistics, data, name, edit}) => {

    const [isOpen, setIsOpen] = React.useState(false);
    const handClose = (e:boolean)=>{
        setIsOpen(false)
    }

    if (name === 'communication' || name === 'ref') {
        return (<div className={'w-full  py-10 px-4 shadow-md'}>
                <table className={'w-full '}>
                    <thead>
                    <tr>
                        {tableName?.map((item, index) => (
                            <th className={`text-start text-gray-light font-normal pr-2 ${item === 'Sent' || item === 'Email' ? 'hidden xl:table-cell' : ""}`}
                                key={index}>{item}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {data?.map((item, index) => {
                        if (name === 'communication') {
                            return (
                                <tr key={index}>
                                    <td className='pr-2 text-start text-base'>{item.registered}</td>
                                    <td className='pr-2 text-start text-base'>{item.productType}</td>
                                    <td className='pr-2 text-start text-base'>{item.subscription}</td>
                                    <td className='pr-2 text-start text-base'>{item.key}</td>
                                    <td className='pr-2 text-start text-base'>{item.expired}</td>
                                    <td className='pr-2 text-start text-base'>{item.days}</td>
                                    <td className='pr-2 text-start text-base'>{item.firstName}</td>
                                    <td className='pr-2 text-start text-base'>{item.lastName}</td>
                                    <td className='pr-2 text-start text-base'>{item.phoneNumber}</td>
                                    <td className='pr-2 text-start text-base'>{item.email}</td>
                                </tr>
                            );
                        } else if (name === 'ref') {
                            return (
                                <>
                                    <tr key={index}>

                                        <>
                                            <td className='pr-2 text-start text-base  truncate hidden xl:table-cell'>{new Date(item.updatedAt).toLocaleDateString().replace(/(\d+)\/(\d+)\/(\d+)/, '$2.$1.$3')}</td>
                                            <td className='pr-2 text-start text-base  truncate'>{item.firstName}</td>
                                            <td className='pr-2 text-start text-base  truncate'>{item.lastName}</td>
                                            <td className='pr-2 text-start text-base  truncate'>{item.phoneNumber}</td>
                                            <td className='pr-2 text-start text-base  truncate hidden xl:table-cell'>{item.email}</td>
                                            <td className='pr-2 text-start text-base  truncate'>{item.status === 'Referred' ? "Waiting" : item.status === 'Not interested' ? 'Declined' : item.status === 'Purchased' ? 'Accepted' : 'Connected'}</td>
                                            {item.status === 'Referred' && (
                                                <td className='pr-2 text-start text-base cursor-pointer truncate'
                                                    onClick={() => {
                                                        if (edit) {
                                                            edit({
                                                                firstName: item.firstName,
                                                                lastName: item.lastName,
                                                                phoneNumber: item.phoneNumber,
                                                                email: item.email,
                                                                id: item.id,
                                                                edit: true
                                                            })
                                                        }
                                                    }}><EditIcon sx={{color: "#5000FF"}}/></td>
                                            )}
                                        </>
                                    </tr>
                                </>
                            )
                        }
                        return (
                            <>
                            </>
                        )

                    })}
                    </tbody>
                </table>
            </div>
        )
    } else if (name === 'videoData') {
        return (
            <div className={'p-4  max-h-[500px]  w-full shadow-2xl max-2xl:max-w-[600px]'}>
                <div className={"rounded-t-lg bg-blue-primary p-4"}>
                    <p className={'text-white text-fontFamily-poppins font-bold'}>UserID:id</p>
                    <p className={'text-white text-fontFamily-poppins font-bold'}>Dr.surname</p>
                    <p className={'text-white text-fontFamily-poppins font-bold'}>
                        status:<span className={'underline'}>status</span>
                    </p>
                </div>
                <div className={'w-full mt-4'}>
                    <table
                        className=" border-separate  border-spacing-y-4 border-spacing-x-0    shadow-inherit ">
                        <thead>
                        <tr>
                            <th className={'font-normal px-2 text-gray-light truncate'}>Age range</th>
                            <th className={'font-normal px-2 text-gray-light truncate'}>Cartoon</th>
                            <th className={'font-normal px-2 text-gray-light truncate'}>Nature</th>
                            {/*<th className={'font-normal px-2 text-gray-light truncate'}>Relax</th>*/}
                            <th className={'font-normal px-2 text-gray-light truncate'}>360VR</th>
                            <th className={'font-normal px-2 text-gray-light truncate'}>Game</th>
                            <th className={'font-normal px-2 text-gray-light truncate'}>Other</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {['3-5', '6-12', '13-18', '19-35', '36+'].map((item, index) => (

                            <tr key={index} className='mt-2'>
                                <td className="bg-blue-light px-2 text-center truncate font-bold rounded-l-full">{item}</td>
                                <td className="bg-blue-light px-2 text-center truncate font-bold">{videoStatistics[item].Cartoon}</td>
                                <td className="bg-blue-light px-2 text-center truncate font-bold">{videoStatistics[item].Nature}</td>
                                {/*<td className="bg-blue-light px-2 text-center truncate font-bold">{videoStatistics[item].Relax}</td>*/}
                                {/*<td className="bg-blue-light px-2 text-center truncate font-bold">videoStatistics.item.</td>*/}
                                <td className="bg-blue-light px-2 text-center truncate font-bold">{videoStatistics[item]['360VR']}</td>
                                <td className="bg-blue-light px-2 text-center truncate font-bold">{videoStatistics[item].Game}</td>
                                <td className="bg-blue-light px-2 text-center truncate font-bold">{videoStatistics[item].Other}</td>
                                <td className="bg-blue-light px-2 text-center truncate font-bold rounded-r-full">

                                    {String((Object.values(videoStatistics[item]) as number[]).reduce((sum, value) => sum + value, 0))} Videos
                                </td>
                            </tr>


                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    } else if (name === 'video') {
        return (<div className={'max-h-[500px] w-full overflow-y-scroll pt-4 pl-4   shadow-2xl max-2xl:w-full '}>
                <div>
                    <p className={' mb-4  text-2xl font-extrabold '}>Videos</p>
                    <div>
                        <table className={'w-full'}>
                            <thead className={'bg-white py-3-2 '}>
                            <tr>
                                <th className={' text-gray-light font-normal pr-2'}>#</th>
                                <th className={' text-gray-light font-normal pr-2'}>Age range</th>
                                <th className={' text-gray-light font-normal pr-2'}>Video</th>
                                <th className={' text-gray-light font-normal pr-2 hidden lg:table-cell'}>Name</th>
                                <th className={' text-gray-light font-normal pr-2'}>Link</th>
                                <th className={' text-gray-light font-normal pr-2'}>Category</th>
                                <th className={' text-gray-light font-normal pr-2 hidden lg:table-cell'}>Added</th>
                            </tr>
                            </thead>
                            <tbody>
                            {videoInfo?.map((item, index) => {
                                // Format the updatedAt date to d.m.y format
                                const date = new Date(item.updatedAt);
                                const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;

                                return (
                                    <tr key={index}>
                                        <td className="p-2 text-center">{index}</td>
                                        <td
                                            className="p-2 text-center"
                                            dangerouslySetInnerHTML={{
                                                __html: Array.isArray(item.age)
                                                    ? item.age.join("<br />")
                                                    : item.age.replace(/Y/g, "<br />"),
                                            }}
                                        ></td>
                                        <td className="p-2 text-center" style={{width: "100px"}}>
                                            <img alt="youtube img" src={item.image}/>
                                        </td>
                                        <td className="p-2 text-center hidden lg:table-cell">{item.title ? item.title : item.channelTitle}</td>
                                        <td className="p-2 text-center overflow-hidden max-w-[150px]">
                                            <a href={item.url} className="block truncate text-blue-500" target="_blank"
                                               rel="noopener noreferrer">
                                                {item.url}
                                            </a>
                                        </td>
                                        <td className="p-2 text-center">{item.category}</td>
                                        <td className="p-2 text-center hidden lg:table-cell">{formattedDate}</td>
                                        {Object.keys(item).includes('user') && <td onClick={() => {
                                            if (edit) {
                                                edit({
                                                    url: item.url,
                                                    age: item.age,
                                                    category: item.category,
                                                    id: item.id,
                                                    title: item.title,
                                                })

                                            }
                                        }} className="p-2 text-center"><EditIcon sx={{color: "#5000FF"}}/></td>}


                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        )
    }
    if(name === 'star'){
        return (
            <div>
                <table className="w-full">
                    <thead className="bg-white py-3">
                    <tr>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2">Date created</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2">Product</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2">QR Scan</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2 hidden lg:table-cell">Name</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2">Age</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2">Gender</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2 hidden lg:table-cell">Phone</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2 hidden lg:table-cell">Own</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2 hidden lg:table-cell">Content</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2 hidden lg:table-cell">Failed</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2 hidden lg:table-cell">Duration</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2 hidden lg:table-cell">Experience</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2 hidden lg:table-cell">Procedure</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2 hidden lg:table-cell">Problem</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2 hidden lg:table-cell">Followup</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2 hidden lg:table-cell">Same</th>
                        <th className="text-gray-light font-normal text-center max-w-14 pr-2 hidden lg:table-cell">Procedure Type</th>

                    </tr>
                    </thead>
                    <tbody>
                    {staData?.map((item, index) => (
                        <tr key={index}>
                            <td className="p-2 text-  max-w-14">
                                {item.createdAt ? new Date(item.createdAt).toLocaleDateString() : "N/A"}
                            </td>
                            <td className="p-2 text-center  max-w-14"  title={item.product?.id} >{item.product?.id}</td>
                            <td className="p-2 text-center truncate max-w-14" > {item.qr || 'false' }</td>
                            <td className="p-2 text-center truncate max-w-14" title={`${item.user?.firstName || ""} ${
                                item.user?.lastName || ""
                            }`}>{`${item.user?.firstName || ""} ${
                                item.user?.lastName || ""
                            }`}</td>
                            <td className="p-2 text-center truncate max-w-14 " title={item.customer?.age}>{item.customer?.age     }</td>
                            <td className="p-2 text-center truncate max-w-14 " title={item.customer?.gender}>{item.customer?.gender   }</td>
                            <td className="p-2 text-center truncate max-w-14 " title={item.user?.phoneNumber}>{item.user?.phoneNumber }</td>
                            <td className="p-2 text-center truncate max-w-14 " title={`${item.own}`}>{`${item.own}`}</td>
                            <td className="p-2 text-center truncate max-w-14 text-blue-500" >

                                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                                        {item.url}
                                    </a>


                            </td>
                            <td title={`${item.failed}`} className="p-2 text-center truncate max-w-6">{`${item.failed}` }</td>
                            <td title={`${item.duration}` } className="p-2 text-center truncate max-w-6">{item.duration }</td>
                            <td title={`${item.experience}`} className="p-2 text-center truncate max-w-6">{item.experience }</td>
                            <td title={`${item.procedure}`} className="p-2 text-center truncate max-w-6">{item.procedure}</td>
                            <td  className="p-2 text-center truncate max-w-6">{item.problem}</td>
                            <td title={`${item.followup}`} className="p-2 text-center truncate max-w-6">{`${item.followup}`}</td>
                            <td title={`${item.same}`} className="p-2 text-center truncate max-w-6">{`${item.same}`}</td>
                            <td title={`${item.procedureType}`} className="p-2 text-center truncate max-w-6">{`${item.procedureType}`}</td>
                            <td className="p-2 text-center truncate max-w-14 cursor-pointer" onClick={()=>{setIsOpen(true)}}><EditIcon sx={{color: "#5000FF"}}/></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                {
                    isOpen &&  <EditModal onClick={handClose}/>

                }
            </div>
        )

    }


    return (
        <>
        </>
    )


});
