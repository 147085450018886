import React              from "react";
import { Button }         from "../../Button";
import { GoToPrevPage }   from "../../GoToPrevPage";
import { Header }         from "../../Header";
import { VideoItem }      from "../../VideoItem";
import {useWizard} from "../../../hooks/useWizard";

const QRCode = () => {
  const [values] = useWizard()
    const {src, title, channelTitle, qr, code} = values.selectedVideo

  return (
      <main className="w-content">
          <div className={'flex justify-between'}>
              <GoToPrevPage/>
              <span className={'text-base font-bold '}>Selected content</span>
              <div className={'w-42'}/>
          </div>
          <div className="py-1 px-4 shadow-xl ">
              <VideoItem src={src} title={title} channelTitle={channelTitle} selected/>
          </div>
          <div className="flex flex-col justify-center items-center  text-center  overflow-hidden ">

              <div className="font-extrabold text-xl text-center ">{`Code:  ${code?.slice(-3)}`}</div>
              <img
                  src={qr}
                  alt="QR-code"
                  className=" h-90 object-contain w-full "
              />
          </div>

          <Header title="Please save the QR code for use in the dental chair "
                  description="The KeppyVR system will scan the QR code and stream your preferred content directly to the AR glasses. Enjoy and relax your time in dental chair."
                  />
          <a href={qr} download="QR_Code.png"><Button text="Save"/></a>
      </main>
  );
};

export default QRCode;
