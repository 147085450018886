import React from 'react'
import {Header} from "../../DoctorHeader/Header";
import {Card} from "../../Card";
import {useDoctorWizard} from "../../../hooks/doctorUseWizard";
import {Table} from "../../Table";
import {TableTest} from "../../DoctorTable";

export const SmsConfig = React.memo(() => {
    const [next, State] = useDoctorWizard();
    return (

        <div className={'ml-[288px] w-[85%] h-full flex '}>
            <div className={'w-full h-full '}>
                <Header className={'pt-5 ml-5'} text={'Products'}/>
                <div className={
                    'border-b-1'
                }>
                    <TableTest rows={[
                        {
                            registered: State?.subscription?.createdAt,
                            productType:  State?.subscription?.productType,
                            subscription: State?.subscription?.subscription,
                            key: State?.key ,
                            expired:  State?.subscription?.expiredAt,
                            days:  State?.subscription?.expiredDays,
                            firstName: State?.user?.firstName,
                            lastName:  State?.user?.lastName,
                            phoneNumber:  State?.user?.phoneNumber,
                            email: State?.user?.email ,
                        }

                    ]} columns={['Registered', 'Product Type', 'Subscription', 'Key', 'Expired', 'Days', 'First Name', 'Last  Name', 'Phone Number', 'Email']}
                               tdClass={'pr-2 text-start text-base'}
                               thClass={'text-start text-gray-light font-normal pr-2 '}/>
                    {/*<Table*/}
                    {/*    name={'communication'}*/}
                    {/*    tableName={['Registered', 'Product Type', 'Subscription', 'Key', 'Expired', 'Days', 'First Name', 'Last  Name', 'Phone Number', 'Email']}*/}
                    {/*    data={[*/}
                    {/*        {*/}
                    {/*            registered: State?.subscription?.createdAt,*/}
                    {/*            productType:  State?.subscription?.productType,*/}
                    {/*            subscription: State?.subscription?.subscription,*/}
                    {/*            key: State?.key ,*/}
                    {/*            expired:  State?.subscription?.expiredAt,*/}
                    {/*            days:  State?.subscription?.expiredDays,*/}
                    {/*            firstName: State?.user?.firstName,*/}
                    {/*            lastName:  State?.user?.lastName,*/}
                    {/*            phoneNumber:  State?.user?.phoneNumber,*/}
                    {/*            email: State?.user?.email ,*/}
                    {/*        }*/}
                    {/*    ]}/>*/}
                </div>
                <div className={'flex h-full w-full py-4  overflow-x-scroll'}>
                    <div className={'flex ml-3 space-x-4'}>
                        <Card id={'1'} surname={'Zargaryan'} />
                    </div>
                </div>

            </div>
        </div>


    )
})