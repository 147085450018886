import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { Statistics }                      from "../DoctorPage/Statistics";
import { SnackbarProvider }                from "notistack";
import { LeftBar }                         from "../LeftBar";
import React, { useContext }               from "react";
import { AuthContext }                     from "../../context/Authprovider";
import { LogIn }                           from "../DoctorPage/DoctorLogin/LogIn";
import { SmsConfig }                       from "../DoctorPage/smsConfig";
import { Referral }                        from "../DoctorPage/Referral";
import { ContentList }                     from "../DoctorPage/Contetnt";
import { ContactUs }                       from "../DoctorPage/contactUs";

function PageWrapper() {
  return (
    <div className={"flex h-full"}>
      <LeftBar/>
      <Outlet/>
    </div>
  );
}

function DoctorRouter({ isLoggedIn }) {
  return (
    <SnackbarProvider maxSnack={3}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                      }}>
      <Routes>
        {!isLoggedIn ? (
          <>
            <Route path="logIn" element={<LogIn/>}/>
            <Route path="*" element={<Navigate replace to="logIn"/>}/>
          </>
        ) : (
          <Route path="*" element={<PageWrapper/>}>
            <Route path="statistics/*" element={<Statistics/>}/>
            <Route path="sms-conf/*" element={<SmsConfig/>}/>
            <Route path="content/*" element={<ContentList/>}/>
            <Route path="ref/*" element={<Referral/>}/>
            <Route path="contactus/*" element={<ContactUs/>}/>
            <Route path="*" element={<Navigate replace to="statistics"/>}/>
          </Route>
        )}
      </Routes>
    </SnackbarProvider>
  );
}

export default DoctorRouter;

