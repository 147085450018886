import React from "react";
import EditIcon from "@mui/icons-material/Edit";

interface  ITable{
    rows: object[],
    columns: string[],
    edit?: boolean,
    editFunc?: (value: any) => void;
    thClass?: string
    tdClass?: string
}

export  const TableTest = React.memo<ITable>(({rows,columns,edit,editFunc ,thClass,tdClass})=>{
    return (
        <div className={'p-4  max-h-[500px]  w-full  max-2xl:max-w-[600px]'}>
            <div className={'w-full '}>
                <table className={'w-full'}>
                    <thead>
                    <tr>
                        {columns?.map((item, index) => (
                            <th className={`${thClass}`}
                                key={index}>{item}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {rows.map((item, index) => (
                        <tr key={index} className='mt-2'>
                            {Object.keys(item).map((key) => {
                                if(key === 'url'){
                                return (
                                    <td className={`${tdClass? tdClass:''} text-blue-500 `}>
                                        <a href={item[key]} target="_blank">
                                            {item[key]}
                                        </a>
                                    </td>
                                )
                            }else{
                                return (
                                <td className={` ${tdClass? tdClass:''} `} title={item[key]} key={key}>{item[key]}</td>

                    )
                    }


                                })}
                    {edit && ((item['status'] && item['status'] === 'Waiting') || item['user'] ) && <td className={'cursor-pointer'} onClick={() => {
                        editFunc(item)
                            }}>
                                <EditIcon sx={{color: "#5000FF"}}/>
                            </td>}
                        </tr>


                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
})