import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import api from '../../../Api';
import {STATISTICS_PATH} from "../../../NetService/doctorConstants";

interface LineChartProps {
    date?: {
        start?: any;
        end?: any;
    };
    setStatistics?:any
}
interface DataItem {
    duration: number;
    experience: number;
    createdAt: string;
}

function fillDate(data: DataItem[], setChartData: any,setStatistics:any) {
    const axis: string[] = [];
    const fail: number[] = [];
    const accept: number[] = [];
    const durationArray: number[] = [];

    data.forEach((item: DataItem) => {
        const { duration, experience, createdAt } = item;
        const isoDate = new Date(createdAt);
        const formattedDate = isoDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

        if (axis[axis.length - 1] !== formattedDate) {
            axis.push(formattedDate);
            fail.push(0);
            accept.push(0);
        }

        if (duration < 120 || experience < 2) {
            fail[fail.length - 1] += 1;
        } else {
            accept[accept.length - 1] += 1;
        }
        durationArray.push(duration);


    });

    const totalArray = fail.map((f, index) => f + accept[index])
    const totalCount = totalArray.reduce((acc, val) => acc + val, 0)


    const cardData = {
        passed: accept.reduce((acc, val) => acc + val, 0),
        fail: fail.reduce((acc, val) => acc + val, 0),
        total: totalCount,
        duration: durationArray.reduce((acc, val) => acc + val, 0)/durationArray.length,
        day: totalCount/totalArray.length,

    }
    if (axis.length === 1) {
        axis.unshift(axis[0]);
        fail.unshift(fail[0]);
        accept.unshift(accept[0]);
    }

    const updatedChartData = {
        xAxis: axis,
        failed: fail,
        accepted: accept,
        total: totalArray,
    };

    setStatistics(cardData)
    setChartData(updatedChartData);
}




function queryCreator(from: any, to?: any){

    let whereParam;
    if (to) {
        from = new Date(from);
        to = new Date(to);
        to.setHours(23,59,59,999)
        if (from > to) {
            whereParam = JSON.stringify({
                createdAt: {
                    "$gt": to.toISOString(),
                    "$lt": from.toISOString()
                }
            });
        } else {
            whereParam = JSON.stringify({
                createdAt: {
                    "$gt": from.toISOString(),
                    "$lt": to.toISOString()
                }
            });
        }

    } else  {
        from = new Date(from);
        to = new Date(from);

        to.setHours(23,59,59,999);
        whereParam = JSON.stringify({
            createdAt: {
                "$gt": from.toISOString(),
                '$lt': to.toISOString()
            }
        });
    }

    return  `${STATISTICS_PATH}?where=${whereParam}`
}






const BasicArea: React.FC<LineChartProps> = ({ date , setStatistics}) => {
    const [chartData, setChartData] = useState({
        xAxis: [],
        failed: [],
        accepted: [],
        total: [],
    });
    const canvasRef = useRef(null);
    const chartInstance = useRef(null);
    useEffect(() => {
        async function getData() {
            let response;
            try {
                if (date) {
                    response = await api.getApi(queryCreator(date.start, date.end));
                } else {
                    const currentDate = new Intl.DateTimeFormat().format(new Date());
                    response = await api.getApi(queryCreator(currentDate));
                }
                fillDate(response.results, setChartData,setStatistics);
            } catch (e) {
                console.error("Error fetching data:", e);
            }
        }
        getData();
    }, [date]);


    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext("2d");
            chartInstance.current = new Chart(ctx, {
                type: "line",
                data: {
                    labels: chartData.xAxis,
                    datasets: [
                        {
                            data: chartData.failed,
                            borderColor: "#979797",
                            tension: 0.4,
                            fill: false,
                        },
                        {
                            data: chartData.accepted,
                            borderColor: "#96A5B8",
                            tension: 0.4,
                            fill: false,
                        },
                        {
                            data: chartData.total,
                            borderColor: "#5000FF",
                            tension: 0.4,
                            fill: false,
                        },
                    ],
                },
                options: {
                    plugins: {
                        legend: { display: false },
                    },
                },
            });
        }
    }, [chartData]);

    return <canvas ref={canvasRef}></canvas>

};

export default BasicArea;
