import React                    from "react";
import { Header }               from "../../DoctorHeader/Header";
import { Field, Form, FormSpy } from "react-final-form";
import api                      from "../../../Api";
import { FORM_ERROR }           from "final-form";
import { TICKETS_PATH }         from "../../../NetService/doctorConstants";
import { useSnackbar }          from "notistack";
import Button from '@mui/material/Button';
import {Box, MenuItem, TextareaAutosize} from "@mui/material";
import {Select, TextField} from "mui-rff";

export const ContactUs = React.memo(() => {
  const { enqueueSnackbar } = useSnackbar();


  const validation = (values: any) => {
    if (!values.title) {
      throw new Error("Please enter a title");
    } else if (!values.message) {
      throw new Error("Please enter a message");
    }
  };

  const onSubmit = async (value: any, form: any) => {
    try {
      validation(value);
      if (!value.title || !value.message) {
        throw new Error();
      }
      await api.postApi(TICKETS_PATH, value);
      enqueueSnackbar("Success", { variant: "success" })

    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar(e.message, { variant: "error" })
      }
      return { [ FORM_ERROR ]: "Something went wrong" };
    }
    form.change("title", "");
    form.change("message", "");

  };

  return (
    <div className={" ml-[288px] w-[85%] h-screen flex flex-col  pt-32 pl-20 max-lg:pl-10"}>
      <div className={"w-[50%]"}>
        <div>
          <Header className={"mb-4 font-extrabold"} text={"Contact Us"}/>
        </div>
        <div className={"flex flex-col gap-y-5"}>
          <div>
            <p className={"font-extrabold  "}>We are allays ready to hear from you: <span
              className={"text-blue-primary "}>+37444488800</span></p>
            <p className={"whitespace-nowrap"}>Working time Mon-Sat , 11AM-5PM , Zone GMT +4 </p>
          </div>
          <Form onSubmit={onSubmit}

                render={({ handleSubmit, form }) => (
                  <form onSubmit={handleSubmit}
                        className={"min-w-[350px]"}>
                    <div className={"flex  flex-col gap-y-4"}>
                      <p className={"font-extrabold"}>Email title</p>
                      <Select
                          name="title"
                          label="Titile"
                          required={true}
                          sx={{
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#5000FF',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#5000FF',

                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#5000FF',
                            },

                          }}
                      >
                        <MenuItem value="Technical Support">Technical Support</MenuItem>
                        <MenuItem value="Bug report">Bug report</MenuItem>
                        <MenuItem value="Information Update">Information Update</MenuItem>
                        <MenuItem value="Colaboration Offer">Collaboration Offer</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                      <Field name={"message"} component={"textarea"}
                      validate={(value)=>(value ? undefined : "Please enter a message")}
                      >
                        {({ input, meta }) => (

                                <Box
                                    sx={{
                                        width: '100%',
                                        borderRadius: '6px',
                                        border: '1px solid #ccc',
                                        padding: '8px',
                                        '&:hover': {
                                            borderColor: '#5000FF',
                                        },
                                        '&:focus-within': {
                                            borderColor: '#5000FF',
                                        },
                                    }}
                                >
                                    <TextareaAutosize
                                        {...input}
                                        name="message"
                                        style={{
                                            minHeight:'150px',
                                            width: '100%',
                                            border: 'none',
                                            outline: 'none',
                                        }}
                                    />
                                </Box>

                        )}
                      </Field>
                    </div>
                    <div className={"flex justify-between"}>
                      <div>
                      </div>
                      <div className={"absolute right-4 bottom-4"}>
                        {/*{submitError && <DescriptionAlerts name="error"/>}*/}
                        {/*{submitSucceeded && (*/}
                        {/*    <>*/}
                        {/*        <DescriptionAlerts name="success"/>*/}
                        {/*    </>*/}
                        {/*)}*/}
                      </div>
                      <div className={"mt-10 flex w-1/2 gap-9"}>
                          <Button variant="outlined" onClick={() => {
                              form.reset();
                          }}
                                  sx={{
                                      width: '100%',
                                      color: '#5000FF',
                                      borderColor: '#5000FF',
                                      paddingY: '14px'
                                  }}>Cancel</Button>
                          <FormSpy subscription={{dirty: true}}>
                              {({dirty}) => (
                                  <Button disabled={!dirty} type={'submit'} variant="contained" sx={{
                                      color: 'white',
                                      width: '100%',
                                      backgroundColor: '#5000FF',
                                      '&:disabled': {
                                          color: '#191919',
                                      },
                                  }}>Save</Button>
                              )}
                          </FormSpy>
                      </div>
                    </div>
                  </form>
                )}
          />


        </div>
      </div>
    </div>
  );

});


