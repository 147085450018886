import {STATISTICS_PATH} from "./NetService/doctorConstants";


class Api {
    baseUrl: string;

    constructor() {
        this.baseUrl = process.env.REACT_APP_SERVER_URL || "";
    }

    async request(path: string, options?: RequestInit) {
        const response = await fetch(`${this.baseUrl}/api/v1/${path}`, options);
        if (response.ok) {
            return response.json();
        }else if(response.status === 401) {
            localStorage.removeItem('DoctorToken');
            localStorage.removeItem('Keepy-doctor');
            window.location.reload();
        }

        throw await response.json();
    }

    async updatereview(data, id) {
        return this.request(`procedures/${id}/updatereview`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
    }

    async verify(data) {
        return this.request("customers/verifywithProcedure", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    async update(data) {
        return this.request("customers/verify", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
    }

    async validate({phoneNumber, code}) {
        return this.request("customers/validate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({phoneNumber, code})
        });
    }


    async resendCode(id: string) {
        return this.request(`customers/${id}/resend`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    async getVideos(productId, age) {
        return this.request(`products/${productId}/videos?where={"age":"${age}"}`);
    }

    async getVideoQR(productId, body) {
        return this.request(`customers/${productId}/procedures/qr`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });
    }

    async getClinicUsers(clinicId) {
        return this.request(`clinics/${clinicId}/products?include=user`);
    }

    async getProduct(productId) {
        return this.request(`products/${productId}?include=user`);
    }


    // --------- doctor
    getDefaultHeaders() {
        const token = localStorage.getItem("DoctorToken")
        return {"Authorization": `Bearer ${token}`}
    };

    async getApi(url: string) {
        const params = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                ...this.getDefaultHeaders()
            }
        }
        return this.request(url, params);
    }

    async putApi(url: string, data: any) {

        return this.request(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                ...this.getDefaultHeaders()
            },
            body: JSON.stringify(data)
        })
    }

    async postApi(url: string, data?: any) {
        return this.request(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                ...this.getDefaultHeaders()
            },
            body: JSON.stringify(data)
        })
    }




    async login(url: string, data: any) {
        return this.request(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        })
    }
}


export default new Api();
