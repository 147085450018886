import React, { useState } from "react";
import {
  format,
  addMonths,
  addDays,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  isSameDay,
  isWithinInterval, startOfYear
}                          from "date-fns";

interface DateRange {
  start: Date | null;
  end?: Date | null;
}
interface DateRangePickerProps {
  handleSubmit: (e: object) => void;
  cancelCal: () => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ handleSubmit, cancelCal }) => {
  const [selectedRange, setSelectedRange] = useState<DateRange>({ start: null, end: null });
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());

  const startDate = startOfWeek(startOfMonth(currentMonth), { weekStartsOn: 1 });
  const endDate = endOfWeek(endOfMonth(currentMonth), { weekStartsOn: 1 });

  const days: Date[] = [];
  let day = startDate;

  while (day <= endDate) {
    days.push(day);
    day = addDays(day, 1);
  }

  const handleDateClick = (date: Date) => {
    if (!selectedRange.start || (selectedRange.start && selectedRange.end)) {
      setSelectedRange({ start: date, end: null });
    } else {
      setSelectedRange({ start: selectedRange.start, end: date });
    }
  };

  const renderDay = (day: Date) => {
    const isSelectedStart = selectedRange.start && isSameDay(day, selectedRange.start);
    const isSelectedEnd = selectedRange.end && isSameDay(day, selectedRange.end);
    const isInRange =
      selectedRange.start &&
      selectedRange.end &&
      isWithinInterval(day, { start: selectedRange.start, end: selectedRange.end });

    return (
      <td
        key={day.toString()}
        onClick={() => handleDateClick(day)}
        style={{
          padding: "10px",
          cursor: "pointer",
          backgroundColor: isSelectedStart || isSelectedEnd ? "#5000FF" : isInRange ? "rgba(80, 0, 255, 0.5) " : "#FFFFFF",
          color: isSelectedStart || isSelectedEnd || isInRange ? "#FFFFFF" : "#000000",
          border: "0px solid #DDDDDD"
        }}
      >
        {format(day, "d")}
      </td>
    );
  };

  const renderCalendar = () => {
    const weeks: JSX.Element[][] = [];
    let daysInWeek: Date[] = [];

    days.forEach((day, index) => {
      daysInWeek.push(day);
      if ((index + 1) % 7 === 0) {
        weeks.push(daysInWeek.map((day) => renderDay(day)));
        daysInWeek = [];
      }
    });

    return weeks.map((week, index) => <tr key={index}>{week}</tr>);
  };

  const handlePreviousMonth = () => {
    setCurrentMonth(addMonths(currentMonth, -1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };
  return (
    <div
      className={"  absolute flex  max-w-[500px]    gap-2 p-5 rounded-lg border-3 bg-blue-light border-blue-primary"}>
      <div className={"flex flex-col justify-between "}>
        <div className={"p-1 cursor-pointer rounded-full bg-blue-100 truncate"} onClick={() => {
          handleSubmit({ start: currentMonth });
        }}>Today
        </div>
        <div className={"p-1 cursor-pointer rounded-full bg-blue-100 truncate"} onClick={() => {
          handleSubmit({ start: addDays(currentMonth, -1), constant: true });
        }}>Yesterday
        </div>
        <div className={"p-1 cursor-pointer rounded-full bg-blue-100 truncate"} onClick={() => {
          handleSubmit({ start: addDays(currentMonth, -7), end: currentMonth, constant: true });
        }}>Last 7 days
        </div>
        <div className={"p-1 cursor-pointer rounded-full bg-blue-100 truncate"} onClick={() => {
          handleSubmit({ start: addDays(currentMonth, -14), end: currentMonth, constant: true });
        }}>Last 14 days
        </div>
        <div className={"p-1 cursor-pointer rounded-full bg-blue-100 truncate"} onClick={() => {
          handleSubmit({ start: addDays(currentMonth, -30), end: currentMonth, constant: true });
        }}>Last 30 days
        </div>
        <div className={"p-1 cursor-pointer rounded-full bg-blue-100 truncate"} onClick={() => {
          handleSubmit({ start: addDays(currentMonth, -90), end: currentMonth, constant: true });

        }}>Last 90 days
        </div>
        <div className={"p-1 cursor-pointer rounded-full bg-blue-100 truncate"} onClick={() => {
          handleSubmit({ start: startOfMonth(currentMonth), end: currentMonth, constant: true });

        }}>This month
        </div>
        <div className={"p-1 cursor-pointer rounded-full bg-blue-100 truncate"}
             onClick={() => {
               handleSubmit({ start: startOfYear(currentMonth), end: currentMonth });

             }}
        >This year
        </div>
      </div>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
          <button onClick={handlePreviousMonth}>{"<"}</button>
          <h2>{format(currentMonth, "MMMM yyyy")}</h2>
          <button onClick={handleNextMonth}> {">"}
          </button>
        </div>
        <table>
          <thead>
          <tr>
            {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
              <th key={day}>{day}</th>
            ))}
          </tr>
          </thead>
          <tbody>{renderCalendar()}</tbody>
        </table>
        <div className={"w-full flex justify-end"}>
          <button className=" text-blue-primary px-4 py-2 rounded-full border-1 border-gray-light"
                  onClick={() => handleSubmit(selectedRange)}
          >
            Apply
          </button>
        </div>
      </div>
      <div onClick={() => cancelCal()} className={" ml-4 cursor-pointer"}>X</div>
    </div>

  );
};

export default DateRangePicker;