import React from "react";
import { Form } from "react-final-form";
import { SelectBox } from "../SelectBox";
import { Button } from "../DoctorButton/Button";
import { DatePicker } from "../dateRangePicker/DatePicker";

const followupOptions = [
    { key: "Yes", value: "Yes" },
    { key: "No", value: "No" },
];

const problemOptions = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
    { key: "4", value: "4" },
    { key: "5", value: "5" },
];

const procedureOptions = [
    { key: "Composite Restorations", value: "Composite Restorations" },
    { key: "Crown Lengthening", value: "Crown Lengthening" },
    { key: "Dental Implant", value: "Dental Implant" },
    { key: "Dental X-Rays", value: "Dental X-Rays" },
    { key: "Dental Cleanings", value: "Dental Cleanings" },
    { key: "Dental Crown", value: "Dental Crown" },
    { key: "Dental Examination", value: "Dental Examination" },
    { key: "Dental Extraction", value: "Dental Extraction" },
    { key: "Dental Filling", value: "Dental Filling" },
    { key: "Exam/ Checkup", value: "Exam/Checkup" },
    { key: "Extractions", value: "Extractions" },
    { key: "Fluoride Application", value: "Fluoride Application" },
    { key: "Orthodontics", value: "Orthodontics" },
    { key: "Pocket Reduction", value: "Pocket Reduction" },
    { key: "Prophylaxis", value: "Prophylaxis" },
    { key: "Pulpotomy", value: "Pulpotomy" },
    { key: "Root Canal", value: "Root Canal" },
    { key: "Silver Diamine Fluoride", value: "Silver Diamine Fluoride" },
    { key: "Sealants", value: "Sealants" },
    { key: "Space Maintainers", value: "Space Maintainers" },
    { key: "Stainless Steel Crowns", value: "Stainless Steel Crowns" },
    { key: "Teeth Whitening", value: "Teeth Whitening" },
    { key: "Tooth Extraction", value: "Tooth Extraction" },
    { key: "Wisdom Teeth Removal", value: "Wisdom Teeth Removal" },
    { key: "X-Rays", value: "X-Rays" },
    { key: "Other", value: "Other" },
];

interface IEditModalProps {
    onClick?: (a: boolean) => void;
}

export const EditModal = React.memo<IEditModalProps>(({ onClick }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [date,setDate] = React.useState(null);

    const handleSubmit = (values: any) => {
        console.log(values,'value')
        const date =  new Date(values);
        console.log(date);
        setDate(date.toLocaleDateString("en-US"));
        setIsOpen(false)
    };
    const handelCancel = ()=>{
        setIsOpen(false);
    }
    console.log(date)
    return (
        <div
            className="fixed inset-0 flex items-center justify-center backdrop-blur rounded-lg"
            role="dialog"
            aria-modal="true"
        >

            {isOpen ? (
                <DatePicker onSubmit={handleSubmit} onCancel={handelCancel} />
            ) : (
                <>

                <div
                    className="absolute inset-0  opacity-30 z-30"
                    aria-hidden="true"
                />
                <div
                    className="relative flex flex-col justify-around bg-white rounded-lg shadow-lg w-[580px] h-[40%] p-6 z-50">
                    <button
                        onClick={() => onClick?.(false)}
                        className="absolute top-3 right-4 text-black py-2 px-4 rounded-full bg-blue-light text-2xl font-bold"
                        aria-label="Close Modal"
                    >
                        &times;
                    </button>
                    <div className="flex flex-col justify-center w-full pr-8 pl-8">
                        <p className="text-lg font-bold mb-4">Patient Information</p>
                        <Form
                            onSubmit={handleSubmit}
                            render={({handleSubmit}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex justify-center gap-3 mt-5">
                                        <div className="flex flex-col gap-5">
                                            <SelectBox
                                                options={procedureOptions}
                                                label="Select procedure type"
                                                name="procedure"
                                                className="pr-10 text-black bg-blue-light font-light border-1 border-green-light"
                                            />
                                            <SelectBox
                                                options={followupOptions}
                                                label="Follow up visit: Yes/ No"
                                                name="followup"
                                                className="pr-10 text-black bg-blue-light font-light border-1 border-green-light"
                                            />
                                        </div>
                                        <div className="flex flex-col gap-5">
                                            <SelectBox
                                                options={problemOptions}
                                                label="Problem level: 1-5"
                                                name="problem"
                                                className="pr-9 text-black bg-blue-light font-light border-1 border-green-light"
                                            />
                                            <button
                                                type="button"
                                                className="p-4 text-black bg-blue-light font-light border-1 border-green-light"
                                                onClick={() => setIsOpen(true)}
                                            >
                                                {date || "Select Date"}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex mt-5 gap-9 justify-center w-full">
                                        <Button text="Save" className="rounded-full shadow-2xl w-[25%]"/>
                                        <Button
                                            text="Send SMS"
                                            className="rounded-full shadow-2xl w-[25%]"
                                            fill={true}
                                        />
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
                </>
            )}
        </div>
    );
})