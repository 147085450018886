import React from 'react';

interface HeaderProps {
  title?: string;
  description?: string;
  className?: string;
  colorText?: string;
  classNameDiv?: string;
  description2?: string
  componentType?: string;
  classNameP?: string;
}
//x
export const Header = React.memo<HeaderProps>(({title, colorText,description,className,classNameDiv, description2,componentType,classNameP }) => {
  return (
    <div className={`${classNameDiv ? classNameDiv : "" }`}>
      <h1 className={`text-customBlack-light text-3xl pb-2 font-extrabold text-center   ${className ? className : ''}
                    max-sm: text-xl`}>
        {title}
      </h1>
        <div>
          {description && <p className={`text-base text-center ${className? className:""}`}>{description}</p>}
          {description2 && <p className={`text-base text-start ${className ? className : ""}`}>{description2}</p>}
        </div>
    </div>
  )
})




