import React from "react";

interface VideoItemProps {
    src?: string,
    title: string,
    channelTitle?: string,
    selected?: boolean
    active?: boolean
}
export const VideoItem = React.memo<VideoItemProps>(({ src, title, channelTitle, selected, active }) => {

    return (
        <div className={`py-2 px-1 flex`}>
            <div className={"flex-1 mr-3 "}>
                <img src={src} alt="" className="w-full"/>
            </div>
            <div className={"flex-[2]" }>
                <p
                    className={`font-bold text-base overflow-hidden`}>{(title.length > 30 ? title.substring(0, 30) + "..." : title)}</p>
                <p className="text-base	text-gray-primary font-light">{channelTitle}</p>
            </div>
        </div>
    );
});