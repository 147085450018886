import { getYoutubeVideo }   from "./getYoutubeVideo";

async function filteringYoutubeVideos(videos: string[]) {
  const currectVideosIds = [];
  const videoDetals = await getYoutubeVideo(videos);
  if (videoDetals) {
    // const times = getTimeByDuration(videoDetals);
    for (let i = 0; i < videoDetals.length; i++) {
      if (!(videoDetals[ i ].orientation.width < videoDetals[ i ].orientation.height)) {
        currectVideosIds.push(videoDetals[ i ]);
      }
    }
    return currectVideosIds;
  }
}

export async function youtubeVideoSerch(name: string) {
  const response = await fetch(`https://www.googleapis.com/youtube/v3/search?part=snippet&key=AIzaSyAF-Q1FXcsRA_mhhzbduKv-u8VIjBlqAoc&type=video&maxResults=10&q=${name}&videoDuration=long`);
  const data = await response.json();
  try{
    const videoIds = data.items.map(item => {
      return item.id.videoId;
    });
    return await filteringYoutubeVideos(videoIds);
  }catch (e){
    throw e
  }



}