import React, { useRef, useState, useEffect } from "react";
import api                                    from "../../Api";
import { useLazyAsync }                       from "../../hooks/useAsync";
import { useWizard }                          from "../../hooks/useWizard";
import { Loader }                             from "../Loader";
import {HelpingMessage} from "../HelpingMessage";

interface EnterCodeProps {
    callback: any;
    reset?: any;
    errorMessage: string
}
export const EnterCode = React.memo<EnterCodeProps>(({ callback, errorMessage, reset }) => {
    const [state] = useWizard();
    const [sendCode, { loading }] = useLazyAsync(() => api.resendCode(state.id), [state]);
    const [code, setCode] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(null);
    const timeoutRef = useRef(undefined);
    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ];
    const resetCode = () => {
        inputRefs.forEach((ref: any) => {
            ref.current.value = "";
        });
        (inputRefs[ 0 ] as any).current.focus();
        setCode("");
    };
    const handleResendCode = (e) => {
        e.preventDefault();
        setDisabled(true);
        resetCode();
        sendCode().catch((e) => {
            console.error(e);
        });
        timeoutRef.current = setTimeout(() => {
            setDisabled(false);
        },10000);
    };
    useEffect(() => {
        if (code.length === 4) {
            if (typeof callback === "function") {
                callback(code);
            }
            //resetCode();
        }
    }, [code]); //eslint-disable-line
    useEffect(() => {
        resetCode();
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [reset]);
    function handleInput(e: any, index: number) {
        const input = e.target;
        const previousInput = inputRefs[ index - 1 ];
        const nextInput = inputRefs[ index + 1 ];
        // @ts-ignore
        const newCode = [...code];
        if (/^[a-z]+$/.test(input.value)) {
            const uc = input.value.toUpperCase();
            newCode[ index ] = uc;
            (inputRefs[ index ] as any).current.value = uc;
        } else {
            newCode[ index ] = input.value;
        }
        setCode(newCode.join(""));
        // input.select();
        if (input.value === "") {
            // If the value is deleted, select previous input, if exists
            if (previousInput) {
                (previousInput as any).current.focus();
            }
        } else if (nextInput) {
            // Select next input on entry, if exists
            (nextInput as any).current.select();
        }
    }
    // Select the contents on focus
    function handleFocus(e: any,index:number) {
        e.target.select();
        setFocusedIndex(index)
    }
    // Handle backspace key
    function handleKeyDown(e: any, index: number) {
        const input = e.target;
        const previousInput = inputRefs[ index - 1 ];
        let ASCIICode = (e.which) ? e.which : e.keyCode;
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
            e.preventDefault();
        }
        if ((e.keyCode === 8 || e.keyCode === 46) && input.value === "") {
            e.preventDefault();
            setCode((prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1));
            if (previousInput) {
                (previousInput as any).current.focus();
            }
        }
    }
    // Capture pasted characters
    const handlePaste = (e: any) => {
        const pastedCode = e.clipboardData.getData("text");
        if (pastedCode.length === 4) {
            setCode(pastedCode);
            inputRefs.forEach((inputRef, index) => {
                (inputRef as any).current.value = pastedCode.charAt(index);
            });
        }
    };
    return (
        <div className="w-full">
            {loading && <Loader/>}
            <div className={'pb-1'}>
                <div className="flex gap-2 justify-between relative mt-2">
                    {[0, 1, 2, 3].map((index) => (
                        <input
                            placeholder={focusedIndex === index ? "" : "x"}
                            className={`text-2xl border-solid border-2 ${errorMessage?'border-red-primary':'border-gray-lighter'} flex p-2 text-center h-20 w-71 rounded-md`}
                            key={index}
                            type="tel"
                            maxLength={1}
                            onChange={(e) => handleInput(e, index)}
                            ref={inputRefs[index]}
                            autoFocus={index === 0}
                            onFocus={(e) => handleFocus(e, index)}
                            onBlur={(e) => setFocusedIndex(null)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onPaste={handlePaste}
                        />
                    ))}
                </div>
                {errorMessage ? <HelpingMessage message={errorMessage}/> : null}
            </div>
            <p className="mt-1 text-base	text-center">Did not receive anything yet? <button
                aria-disabled={loading}
                disabled={disabled}
                className="font-bold text-blue-primary whitespace-nowrap border-b-2"
                onClick={handleResendCode}>Resend the code</button></p>
        </div>
    );
});