import React from "react";
import {Form} from "react-final-form";
import {useWizard} from "../../../hooks/useWizard";
import {Button} from "../../Button";
import {GoToPrevPage} from "../../GoToPrevPage";
import {Header} from "../../Header";
import {SelectBox} from "../../SelectBox";
import {Field} from "react-final-form";
import {Input} from "../../Input";
import {useLazyAsync} from "../../../hooks/useAsync";
import api from "../../../Api";
import {HelpingMessage} from "../../HelpingMessage";

const Greeting = () => {
    const [call] = useLazyAsync((data) => api.update(data), []);
    let [state, next] = useWizard();

    const options = [
        {
            key: "3-5",
            value: "3-5Y"
        },
        {
            key: "6-12",
            value: "6-12Y"
        },
        {
            key: "13-18",
            value: "13-18Y"
        },
        {
            key: "19-35",
            value: "19-35Y"
        },
        {
            key: "36+",
            value: "36Y+"
        },
    ];

    const genderOptions = [
        {
            key: "male",
            value: "Male"
        },
        {
            key: "female",
            value: "Female"
        },
        {
            key: "Other",
            value: "Other"
        },
        {
            key:'Prefer not to say',
            value: 'Prefer not to say'
        }
    ]

    const handleSubmit = async  (values: any) => {
        state = {
            ...state,
            ...values
        }
        await  call(state)
        next("congrats", values);
    }
//x
    return (
        <main className="w-content">
            <div>
            <GoToPrevPage className={'inline-block'}/>
            </div>
            <div className="flex flex-col justify-center items-center overflow-hidden flex-grow-1  ">
                <img src="/keppy_done.webp?v=20241019" alt="Keppy men" className="content-img
                        ms:h-300 ms:w-375"/>
            </div>
            <Header title={'3th step 👋'}
                    description="We are almost done, we need more information so we can offer you the best possible personalized service"
                    classNameDiv={'md:max-sm: pb-20 sm: pb-5'}
            />
            <Form
                onSubmit={handleSubmit}
                validate={(values: any) => {
                    const errors: any = {};

                    if (!values.name || !values.age || !values.gender) {
                        errors.fill = 'Please fill in all fields'
                    }
                    return errors;
                }}
                render={({handleSubmit, errors}) => (
                    <>
                        <form onSubmit={handleSubmit} style={{display: "contents"}}>
                            <Field name="name" component={'input'}>
                                {({input, meta}) => (
                                    <Input
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        className={`bg-white border border-solid  mb-2   placeholder-poppins  placeholder-text-base `}
                                        {...input}/>
                                )}
                            </Field>
                            <SelectBox
                                name="age"
                                options={options}
                                label="Select age range"
                            />
                            <SelectBox
                                name="gender"
                                options={genderOptions}
                                label="Gender"

                            />
                            <Field name={'fill'} component={'textarea'}>
                                {({input, meta}) => (
                                    meta.error && meta.touched ? <HelpingMessage message={meta.error}/> : null
                                )}
                            </Field>

                            <Button text="Next" type="submit" className={`mt-12 p-5 ${Object.keys(errors).length === 0 ? "bg-blue-primary" : "bg-gray-primary"} `}
                                    disabled={Object.keys(errors).length !== 0 }/>
                        </form>
                    </>
                )}
            />
        </main>
    );
};

export default Greeting;