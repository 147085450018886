

export async function getYoutubeVideo(videoId: string | string[]) {
   try {
     const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails&id=${videoId}&key=AIzaSyAF-Q1FXcsRA_mhhzbduKv-u8VIjBlqAoc`);
     const data = await response.json();
     if (data.items.length === 0) {
       throw new Error('Video not found');
     }
       const videos = data.items.map((video: any) => {
           const { snippet, contentDetails } = video;
           const orientation = snippet.thumbnails.medium || snippet.thumbnails.default;

           return {
               id: video.id,
               title: snippet.title,
               description: snippet.description,
               channelTitle: snippet.channelTitle,
               orientation: orientation,
               duration: contentDetails.duration,
               definition: contentDetails.definition,
               categoryId: snippet.categoryId,
           };
       });
       if(videos.length === 1) {
           return videos[0]
       }else {
           return videos;
       }

   } catch (error) {
     console.log('Fetching Youtube data Error',error);
     return null;
   }
}