import {useCallback, useEffect, useRef} from "react";
import React, { useState } from "react";
import { Field }           from "react-final-form";
import { Button }          from "../Button";
import { HelpingMessage }  from "../HelpingMessage";
import { Message }         from "../Message";
import { Modal }           from "../Modal";
import {log} from "node:util";

interface Country {
  name: string;
  dialCode: string;
  isoCode: string;
  flag: string;
}

interface CountrySelectorWithDetectionProps {
  countries: Country[];
  onSelect: (country: Country | undefined) => void;
}

const CountrySelectorWithDetection: React.FC<CountrySelectorWithDetectionProps> = ({ countries, onSelect }) => {
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>({
    "name": "United States",
    "dialCode": "+1",
    "isoCode": "US",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/us.svg"
  });
  const [countryList, setCountryList] = useState<Country[]>(countries);
  const [show, setShow] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleOpen = useCallback(() => setShow(true), []);

  const handleClose = useCallback(() => setShow(false), []);

  const detectCountry = (country: string): Country | undefined => {
    return countries.find((c) => country === c.name);
  };

  const handleSelected = (e: any) => {
    const newCountry = e.target.innerText;
    const findCountry = detectCountry(newCountry);
    setSelectedCountry(findCountry);
    onSelect(findCountry);
    handleClose();
  };

  const handleSearch = (e: any) => {
    const val = e.target.value;
    setSearchValue(val);
    const searchTerm = val.toLowerCase();
    const arr: Country[] = [];
    countries.forEach((item) => {
      const text = item.name.toLowerCase();
      if (text.includes(searchTerm)) {
        arr.push(item);
      }
    });
    setCountryList(arr);
  };

  const handleKeyPress = (evt: any) => {
    let ASCIICode = (evt.which) ? evt.which : evt.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
      evt.preventDefault();
    }
  };
  const pageEnd:any = useRef()

  useEffect(() => {
    if(pageEnd.current){
      pageEnd.current.scrollIntoView({ behavior: 'smooth' });

    }
  }, [showPrivacy]);
  return (
    <div>
      <div className="flex items-center">
        <div className="w-full">
          <Field
            name="phoneNumber"
            component="input"
            type="text"
          >
            {({ input, meta }) => {
              return <>
                <div className="w-full flex flex-row items-center">


                  <div className={`flex items-center border border-solid ${meta.touched && (!!meta.error || (!meta.modifiedSinceLastSubmit && !!meta.submitError)) ? "border-red-primary" : "border-gray-lighter"} h-55 rounded-lg pr-4 mr-3`}
                       onClick={handleOpen}>
                       <img src={selectedCountry.flag} alt={`Flag of ${selectedCountry.name}`}
                         className="h-31 w-31 object-cover items-center  rounded-full p-1 mr-2"
                      />
                      <div className="text-gray-500 ">
                        <svg className="w-6 h-6 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                      </svg>
                    </div>
                  </div>

                  <input
                      placeholder="Phone number"
                      {...input}
                      type={'tel'}
                      onKeyPress={(e) => handleKeyPress(e)}
                      className={`border border-solid rounded-lg text-black p-4 w-full text-base ${meta.touched && (!!meta.error || (!meta.modifiedSinceLastSubmit && !!meta.submitError)) ? "border-red-primary" : "border-gray-lighter"}`}
                  />

                </div>
                <div
                    className={'flex items-center mt-2 justify-between'}
                >
                  <div className={'min-w-16'}>
                    {meta.touched && (!!meta.error || (!meta.modifiedSinceLastSubmit && !!meta.submitError)) && (
                        <>
                          <HelpingMessage message={meta.error || meta.submitError} />
                        </>
                    )}

                  </div>
                  <a className="text-sm ml-3.5"><span
                    className="text-blue-primary font-bold underline"
                    onClick={() => setShowPrivacy(true)}>Privacy Policy</span></a>
                </div>
              </>;
            }}
          </Field>
        </div>
      </div>
      <Modal open={show} title="Select a country" onClose={handleClose} search={true} searchValue={searchValue}
             onSearch={(e) => handleSearch(e)} className="min-h-full">
        <div>
          {countryList.map((option) => (
            <p key={option.name} onClick={handleSelected} className="my-1">
              {option.name}
            </p>
          ))}
        </div>
      </Modal>
      <Modal  closeButton={false} open={showPrivacy} onClose={() => setShowPrivacy(false)}
             rootClassName="bg-[rgba(255, 255, 255, 0.2)] backdrop-blur-sm" className="rounded-10 shadow-xl">
        <div className="privacy-policy flex flex-col justify-center items-center">
          <h1 className={"text-2xl font-bold dark:text-white"}>Privacy Policy</h1>
          <p>This Privacy Policy explains how the Keppy VR application collects information from its prospective
            customers and visitors.</p>

          <p>COLLECTION OF PERSONAL INFORMATION<br/>
            Personal information we collect directly from you includes your name, age range, gender, and mobile number
            during the content selection procedure for our virtual reality-based system.</p>

          <p>
            USE OF PERSONAL INFORMATION<br/>
            We use information collected directly from you for post-procedural purposes, such as providing dentist
            feedback, communicating to obtain your reviews for our services, and sharing other relevant information. We
            may also use this information to market our products and services to you, including text messages subject to
            your consent.
          </p>
          <p>
            DISCLOSURE OF PERSONAL INFORMATION<br/>
            We may use third-party service providers to assist us in providing and marketing our products and services
            to you, and we may share your information with such third parties for these limited purposes.
          </p>
          <p>
            TEXT MESSAGE COMMUNICATIONS<br/>
            If you wish to stop receiving text messages from us, reply STOP, QUIT, CANCEL, OPT-OUT.
          </p>
          <p>
            CONTACT INFORMATION<br/>
            If you have any questions, please contact us at support@10ximmersive.com
          </p>
          <div ref={pageEnd} />
          <Button style={{ margin: 15 }} onClick={() => setShowPrivacy(false)} text={"Got it"} type="button"/>
        </div>
      </Modal>
    </div>
  );
};

export default CountrySelectorWithDetection;
