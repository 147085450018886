import { useNavigate } from "react-router-dom";
import {useEffect, useState} from "react";
import { format } from "date-fns";

interface UserData {
    user: object;
    subscription: object;
    accessToken: string;
}


export type Next = (path: string, data?: UserData) => void;
export type State = {
    user?: {
        firstName?: string;
        lastName?: string;
        phoneNumber?: string;
        email?: string;
    };
    subscription?: {
        productType:string,
        expiredAt: string,
        subscription: number,
        quantity:number,
        createdAt?: string;
        expiredDays?:number
    };
    key:string;
    // Add more fields here based on your UserData type
};
function calculateDaysDifference(targetDateStr: string): number {
    // Parse the target date (assuming it's in dd.MM.yyyy format)
    const [day, month, year] = targetDateStr.split(".").map(Number);
    const targetDate = new Date(year, month - 1, day);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = targetDate.getTime() - currentDate.getTime();

    // Convert milliseconds to days
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

    return diffInDays;
}



export function useDoctorWizard(): [Next, State | null] {
    const navigate = useNavigate();
    const [State, setState] = useState<State | null>(null);
    useEffect(() => {
        const savedData = localStorage.getItem('Keppy-doctor');
        if (savedData) {
            setState(JSON.parse(savedData)); // Load data from localStorage
        }
    }, []);

    const next: Next = (path: string, data: any = null) => {
        if (data) {
            data.subscription.expiredAt = format(new Date(data.subscription.expiredAt), "dd.MM.yyyy");
            data.subscription.createdAt = format(new Date(data.subscription.createdAt), "dd.MM.yyyy")

            data.subscription.expiredDays = calculateDaysDifference(data.subscription.expiredAt);
            const newSaveData = { user: data.user, subscription: data.subscription, key: data.key };
            setState(newSaveData);
            localStorage.setItem('Keppy-doctor', JSON.stringify(newSaveData))
            localStorage.setItem('DoctorToken',data.accessToken)
        }

        navigate(`/doctorAdmin/${path}`);
    };
    return [next,State];
}

