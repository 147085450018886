import { useContext }       from "react";
import React                from "react";
import { Navigate, Routes } from "react-router-dom";
import { Route }            from "react-router-dom";
import { Flow }             from "./components/Flow";
import LogoPage             from "./components/Page/LogoPage";
import Clinic               from "./components/Page/Clinic";
import { AuthContext }      from "./context/Authprovider";
import { routes }           from "./routes";
import DoctorRouter         from "./components/DocotorRouter/DoctorRouter";

export function App() {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <Routes>
      <Route path="/" element={<LogoPage/>}/>
      <Route path="/doctorAdmin/*" element={<DoctorRouter isLoggedIn={isLoggedIn}/>}/>
      <Route path="/clinic/:id/*" element={<Clinic/>}/>
      <Route path={"/:id/*"} element={<Flow/>}>
        {routes.map(route => {
          return (
            <Route
              path={route.path}
              key={route.path}
              element={React.createElement(route.component)}
            />
          );
        })}
        <Route path="*" element={<Navigate to="start" replace/>}/>
      </Route>
    </Routes>
  );
}
