import React, { useEffect } from "react";
import { useWizard }        from "../../../hooks/useWizard";
import { Button }           from "../../Button";
import { Header }           from "../../Header";

const VirtualReality = () => {
  const [value, next, setState] = useWizard();
  const onSubmit = (event: React.FormEvent, values: any) => {
    event.preventDefault();
    next("verifying");
  };

  useEffect(() => {
    setState({});
    localStorage.clear();
  }, []);

  return (
      <div className="items-center flex flex-col h-screen m-0 p-0" >
        <div className={'h-full' +
            ' max-sm: mt-0'}>
          <img className={"h-full"} src="/siting_boy.webp?v=20241019" alt="Keppy men"/>
        </div>
        <div className={"flex flex-col gap-2  items-center absolute bottom-9 w-9/12" +
            "max-sm: bottom-14 px-5"}>
          <form className={"flex flex-col items-center"} onSubmit={(e) => onSubmit(e, value)}>
            <Header title="Enjoy comfort and relaxation in the dental chair with our AR/VR technology 🚀"
                    className=""/>
            <Button text="Start" type="submit"/>
          </form>
        </div>
      </div>
  );
};

export default VirtualReality;