import React    from "react";
import { Link } from "react-router-dom";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
  text: string;
  type?: "submit" | "reset" | "button";
  href?: string;
  target?: string;
  className?: string;
  isInvalid?: boolean;
}

export const Button = React.memo<ButtonProps>(({className,type, text,isInvalid, href,target='self',...rest}) => {

  return (
    <button type={type} className={`bg-blue-primary text-white py-4 !font-black text-lg rounded-full w-full ${className?className:""}`}{...rest}>
          {href ? <Link to={href} target={`_${target}`}>{text}</Link> : <span>{text}</span>}
    </button>
  )
})