import React, {useState} from "react";
import {Button} from "../DoctorButton/Button";


interface DatePickerProps {
    onSubmit: (date: Date) => void;
    onCancel: () => void;
}

export const  DatePicker = React.memo<DatePickerProps>(({onSubmit, onCancel}) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [takenDay,setTakenDay] = useState(new Date());



    const startDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(),1);
    const endDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth()+1,0);


    const days = [];
    let day = new Date(startDate);

    while (day <= endDate) {
        days.push(new Date(day));
        day.setDate(day.getDate() + 1);
    }


    //week
    const getWeekDays = () => {
        const weeks = [];
        let oneWeek = []
        days.forEach((day, index) => {
            oneWeek.push(day);
            if((index+1) %7 === 0 || index === days.length - 1){
                weeks.push(oneWeek);
                oneWeek = [];
            }


        })

        return weeks.map((week, index) => (
            <tr key={index}>
                {week.map((day, i) => (
                    <td
                        key={i}
                        onClick={() => {
                            setTakenDay(day); // Set the numeric day of the month
                        }}
                        className={`cursor-pointer p-3 ${
                            takenDay?.getDate() === day.getDate() ? "bg-blue-primary text-white" : ""
                        }`}
                    >
                        {day.getDate()} {/* Render day of the month */}
                    </td>
                ))}
            </tr>
        ));

    }
    const handleNextMonth = () => {
        setCurrentMonth(prevMonth => new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 1));
    }
    const handlePrevMonth = () => {
        setCurrentMonth(prevMonth => new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1));
    };
    return (
        <div
            className={'relative flex  justify-center bg-white rounded-lg shadow-lg w-[580px] h-[40%] p-6 z-50'}>
            <button
                onClick={() => onCancel()}
                className="absolute top-3 right-4 text-black py-2 px-4 rounded-full bg-blue-light text-2xl font-bold"
                aria-label="Close Modal"
            >
                &times;
            </button>
            <div className="w-10/12">
                <div className={'flex justify-between text-white bg-blue-primary p-2'}>
                    <div onClick={handlePrevMonth} className={'cursor-pointer'}>
                        {'<'}
                    </div>
                    {new Intl.DateTimeFormat('en-US', {month: 'long'}).format(currentMonth)}
                    <div onClick={handleNextMonth} className={'cursor-pointer'}>
                        {'>'}
                    </div>
                </div>
                <div className={'flex justify-center '}>
                    <table>
                        <thead>
                        <tr>
                            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
                                <th key={day}>{day}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>{getWeekDays()}</tbody>
                    </table>
                </div>
                <Button text={'Apply'} onClick={() => {
                    if (takenDay) {
                        onSubmit(takenDay);

                    }
                }}/>
            </div>
        </div>
    )
})

