import React from "react";

interface BottonProps {
    text?: string;
    className?: string;
    type?: 'button' | 'submit' | 'reset';
    fill?: boolean;
    onClick?: () => void;
    disabled?: boolean;


}

export const Button = React.memo<BottonProps>(({fill,onClick,text,className,type,disabled = false})=>{
    return (
      <button onClick={onClick} type={type} className={`${fill ? 'bg-blue-primary text-white ' : 'border-1 border-blue-primary text-gray-dark' } text-center py-4 !font-black text-lg  w-full ${className?className:""}`}
            disabled={disabled}>
          <span>{text}</span>
      </button>
    )
});
