import React           from "react";
import { useNavigate } from "react-router-dom";
//x
interface GoToPrevPageProps {
  text?: string;
  className?: string;

}
export const GoToPrevPage = React.memo<GoToPrevPageProps>(({ text ,className}) => {
  const navigate = useNavigate();
  const onClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  return (
      <div className="inline-block">
        <a
            onClick={onClick}
            href="#"
            className={`${className ? className : ""} inline-block`}
        >
          <img
              src="/arrow-left.svg?v=20241019"
              alt="Go to prev page"
              className={`h-6 ${text && "float-left"}`}
          />
          {text && <span className="text-lg pl-1 font-bold">{text}</span>}
        </a>
      </div>
  );
});
