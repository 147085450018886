import React, { useCallback, useEffect } from "react";
import { Header }                        from "../../DoctorHeader/Header";
import { IEditProps, Table }             from "../../Table";
import { Field, Form, FormSpy }          from "react-final-form";
import { FORM_ERROR }                    from "final-form";
import api                               from "../../../Api";
import { REFERRALS_PATH }                from "../../../NetService/doctorConstants";
import Button from '@mui/material/Button';
import { useSnackbar }                   from "notistack";
import TextField from "@mui/material/TextField";
import {TableTest} from "../../DoctorTable";

interface IDate {
    updatedAt: string;     firstName: string;     lastName: string;     phoneNumber: string;     email: string;     status: string;
}



export const Referral = React.memo(() => {
  //todo mekel es reset State ic azatvi, vonc nayum em petqakan ban chi
  const [reset, setReset] = React.useState(false);
  const [data, setData] = React.useState<IDate[]>([]);
  const [edit, setEdit] = React.useState<IEditProps>();
  const { enqueueSnackbar } = useSnackbar();

  const dataPreProcessing = useCallback((data: IDate[]) => {
      let newData = data.map((item) => {
          console.log(item.status, 'statusName');
          return {
              updatedAt: new Date(item.updatedAt)
                  .toLocaleDateString()
                  .replace(/(\d+)\/(\d+)\/(\d+)/, '$2.$1.$3'),
              firstName: item.firstName,
              lastName: item.lastName,
              phoneNumber: item.phoneNumber,
              email: item.email,
              status:
                  item.status === 'Referred'
                      ? 'Waiting'
                      : item.status === 'Not interested'
                          ? 'Declined'
                          : item.status === 'Purchased'
                              ? 'Accepted'
                              : 'Connected',
          };
      });
      setData(newData)
  }, [])

  async function getData() {
    try {
      const result = await api.getApi(REFERRALS_PATH);
     dataPreProcessing(result.results);
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar(e.message, { variant: "error" });

      }
    } finally {
      setEdit({});

    }
  }
  useEffect(() => {
    getData();
  }, []);

  const validation = (values: any) => {
    const  errors: { [key: string]: string } = {}
      const validNumber = new RegExp(/^[\+][0-9]{8,}$/);
      if (!validNumber.test(values.phoneNumber)) {
        errors.phoneNumber = "Invalid phone number";
      }
    const validEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
    if (!validEmail.test(values.email)) {
     errors.email = "Invalid email address";
    }
    console.log(errors);
    return errors;
  };

  const onSubmit = async (values: any,form ) => {

   if (values.edit) {
      try {
        const url = REFERRALS_PATH + "/" + values.id;
        await api.putApi(url, values);
        enqueueSnackbar("Success", { variant: "success" });
          console.log(values)
          form.reset()
          setEdit(undefined);

          getData();
      } catch (e) {
        if (e instanceof Error) {
          enqueueSnackbar(e.message, { variant: "error" });
          setReset(!reset);

        }
        return { [ FORM_ERROR ]: "Something went wrong. Please try again." };
      }
    } else {
      try {
        if (values) {
          const result = await api.postApi(REFERRALS_PATH, values);
          if (result) {

              dataPreProcessing([
                  {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      phoneNumber: values.phoneNumber,
                      email: values.email,
                      updatedAt: `${new Date()}`,
                      status: "Referred",
                  },
                  ...data
              ]);

              form.reset()
            enqueueSnackbar("Success", { variant: "success" });
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          enqueueSnackbar(e.message, { variant: "error" });

        }
        return { [ FORM_ERROR ]: "Something went wrong. Please try again." };
      }
    }
      console.log(data,'new data')

  };
  const handleEdit = useCallback((value: any) => {
    setEdit(value);
  }, []);
  return (
    <div className={"ml-[288px] w-[85%] h-screen flex flex-col gap-2 "}>
      <Header className={"pt-5 ml-5"} text={"Referral"}/>
      <div className={"max-h-[200px] overflow-y-scroll "}>
          <TableTest columns={["Sent", "First Name", "Last  Name", "Phone Number", "Email", "Status"]} rows={data} edit={true}
                     tdClass={'text-start text-base  truncate'}
                     thClass={'text-start text-gray-light font-normal pr-2'}
                     editFunc={handleEdit}
          />
        {/*<Table*/}
        {/*  name={"ref"}*/}
        {/*  tableName={["Sent", "First Name", "Last  Name", "Phone Number", "Email", "Status"]}*/}
        {/*  data={data}*/}
        {/*  edit={handleEdit}/>*/}
      </div>

      <div className={"flex flex-col gap-9 px-7 pt-3"}>
        <div>
          <p>Help Keppy to spread out all over the world. The provided information will be used for contact purposes
            ONLY. </p>
          <p>We’ll offer you an extra 1-month subscription after successful referral registration. </p>
        </div>
        <div className={"flex gap-2 w-full"}>
          <Form onSubmit={onSubmit}
                validate={validation}
                initialValues={edit ? edit : {
                  lastName: "",
                  firstName: "",
                  phoneNumber: "",
                  email: ""
                }}

                render={({ handleSubmit, form }) => (
                  <form onSubmit={handleSubmit} className={"w-full"}>
                    <div className="flex flex-wrap gap-9 w-full  items-start max-lg:flex-col">
                      <div className="flex-1 flex flex-col gap-2">
                        <p className="font-extrabold truncate">First Name</p>
                        <Field name="firstName" >
                          {({ input, meta }) =>  <TextField label="First Name" variant="outlined"
                                                            {...input}
                                                            // disabled={!edit}
                                                            required={true}
                                                            className={'w-full'}
                                                            error={meta.touched && Boolean(meta.error)}
                                                            sx={{
                                                              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#5000FF',
                                                              },
                                                              '& .MuiInputLabel-root.Mui-focused': {
                                                                color: '#5000FF',
                                                              }
                                                            }}
                                                           />}
                        </Field>
                      </div>

                      <div className="flex-1 flex flex-col gap-2">
                        <p className="font-extrabold truncate">Last Name</p>
                        <Field
                            name="lastName"

                        >
                          {({ input, meta }) => (
                              <TextField label="Last Name" variant="outlined"
                                         {...input}
                                         // disabled={!edit}
                                         required={true}
                                         className={'w-full'}

                                         sx={{
                                           '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                             borderColor: '#5000FF',
                                           },
                                           '& .MuiInputLabel-root.Mui-focused': {
                                             color: '#5000FF',
                                           }
                                         }}/>
                          )}
                        </Field>
                      </div>

                      <div className="flex-1 flex flex-col gap-2">
                        <p className="font-extrabold truncate">Phone Number</p>
                        <Field name="phoneNumber" >
                          {({ input, meta }) => <TextField label="Phone Number" variant="outlined"
                                                           {...input}
                                                           // disabled={!edit}
                                                           required={true}
                                                           className={'w-full'}
                                                           error={meta.touched && Boolean(meta.error) && meta.dirty}
                                                           sx={{
                                                             '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                               borderColor: '#5000FF',
                                                             },
                                                             '& .MuiInputLabel-root.Mui-focused': {
                                                               color: '#5000FF',
                                                             }
                                                           }}
                                                           />}
                        </Field>
                      </div>

                      <div className="flex-1 flex flex-col gap-2">
                        <p className="font-extrabold truncate ">Email</p>
                        <Field name="email" >
                          {({ input, meta }) =>  <TextField label="Email" variant="outlined"
                                                            {...input}
                                                            // disabled={!edit}
                                                            required={true}
                                                            className={'w-full'}
                                                            error={meta.touched && Boolean(meta.error) && meta.dirty}
                                                            sx={{
                                                              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#5000FF',
                                                              },
                                                              '& .MuiInputLabel-root.Mui-focused': {
                                                                color: '#5000FF',
                                                              }
                                                            }}

                          />}
                        </Field>
                      </div>

                      <div className="flex w-1/3 gap-4 mt-8 ml-auto max-lg:m-0">
                        <Button variant="outlined" onClick={() => {
                          form.reset();
                          setEdit(undefined);
                        }}
                                sx={{
                                  width: '100%',
                                  color: '#5000FF',
                                  borderColor: '#5000FF',
                                  paddingY: '14px'
                                }}>Cancel</Button>
                        <FormSpy subscription={{dirty: true}}>
                          {({dirty}) => (
                              <Button disabled={!dirty} type={'submit'} variant="contained" sx={{
                                color: 'white',
                                width: '100%',
                                backgroundColor: '#5000FF',
                                '&:disabled': {
                                  color: '#191919',
                                },
                              }}>Save</Button>
                          )}
                        </FormSpy>
                      </div>
                    </div>
                    <div className={"absolute right-4 bottom-4"}>
                      {/*{submitError && <DescriptionAlerts name="error"/>}*/}
                      {/*{submitSucceeded && <DescriptionAlerts name="success"/>}*/}
                    </div>
                  </form>
                )}
          />
        </div>
      </div>
    </div>
  );
})