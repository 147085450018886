import React from "react";

interface CardProps {
    text: string;
    number?: number;
}



export const StatCard = React.memo<CardProps>(({text,number}) => {
    return (
        <div
            className={'w-1/3  flex flex-col justify-center items-center rounded-2xl border-2 border-blue-primary shadow-2xl p-2'}>
            <p className={'text-gray-light text-center truncate'}>{text}</p>
            <div className={'flex justify-around'}>
                <div className={'flex justify-center items-center w-1/2 pr-2'}>
                    <span className={'text-xl font-bold'}>{Math.ceil(number)}</span>
                </div>

                {/*<span className={'text-blue-middle w-1/2 font-bold'}>*/}
                {/*    {`0 % from last week`}*/}
                {/*</span>*/}
            </div>
        </div>
    )
})