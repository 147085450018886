import React from "react";

interface HeaderProps {
    text: string;
    className?: string;
}

export const Header = React.memo<HeaderProps>(({ text,className }) => {
    return (
        <h1 className={`text-xl ${className}`}>{text}</h1>
    );
});
