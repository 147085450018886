import { useMemo }     from "react";
import React           from "react";
import { useNavigate } from "react-router-dom";
import { useParams }   from "react-router-dom";
import { useAsync }    from "../../../hooks/useAsync";
import { useWizard }   from "../../../hooks/useWizard";
import { Button }      from "../../Button";
import { Header }      from "../../Header";
import { Loader }      from "../../Loader";
import NotFound        from "../../NotFound";
import { SelectBox }   from "../../SelectBox";
import { Form }        from "react-final-form";
import api             from "../../../Api";

const Clinic = () => {
  const [_, next, setValue] = useWizard();
  const { id } = useParams();
  const { data: { results = [] } = {}, error, loading } = useAsync(() => api.getClinicUsers(id), [id]);
  const options = useMemo(() => results.map(p => ({ key: p.id, value: `${p.user.firstName} ${p.user.lastName}` })), [results]);
  const navigate = useNavigate();
  const onSubmit = async (values: any) => {
    setValue(values);
    navigate(`/${values.doctor}/start`);
  };

  if (loading) {
    return <Loader/>;
  }

  if (error || !results.length) {
    return <NotFound/>;
  }

  return (
    <main className="w-content">
      <Header title="Welcome"
              description="Please select the name of the doctor with whom you have an appointment" className="pt-6.5"/>
      <div className="flex flex-col justify-center items-center overflow-hidden flex-grow-1 h-0">
        <img src="/Keppy.svg?v=20241019" alt="Keppy"/>
        <img src="/Keppy_men.png?v=20241019" alt="Keppy men" className="h-[inherit] content-img"/>
      </div>
      <Form<FormData>
        onSubmit={onSubmit}
        validate={(values: any) => {
          const errors: any = {};
          if (!values.doctor) {
            errors.doctor = "Please select doctor";
          }
          return errors;
        }}
        render={({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit} className={"flex flex-col gap-2"}>
            <SelectBox
              name={"doctor"}
              options={options}
              label="Select doctor"
            />
            <Button text="Next" type="submit"/>
          </form>
        )}
      />
    </main>
  );
};

export default Clinic;
