import React from "react";

interface HelpingMessageProps {
  message:string
}

export const HelpingMessage = React.memo<HelpingMessageProps>(({message}) => {
  return(
    <p>
      <span className="text-red-primary text-sm">{message}</span>
    </p>
  )
})