import * as React from "react";
import api from "../../../Api";
import {STATISTICS_PATH} from "../../../NetService/doctorConstants";
import {useCallback, useEffect} from "react";
import {StatCard} from "./StatCard";
import {useDoctorWizard} from "../../../hooks/doctorUseWizard";
import DateRangePicker from "../../dateRangePicker/DateRangePicker";
import BasicArea from "./diagram";
import {Header} from "../../DoctorHeader/Header";
import {MyModal} from "../../DoctorModal/Modal";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {Table} from "../../Table";
import {EditModal} from "../../DoctorModal/EditModal";
import {TableTest} from "../../DoctorTable";

type TableState = {
    createdAt: string;
    product: string;
    qr: string;
    name: string;
    age: string;
    gender: string;
    phoneNumber: string;
    own: string;
    url: string;
    failed: string;
    duration: string;
    experience: string;
    procedure: string;
    problem: number;
    followup: string;
    same: string;
    procedureType: string;
}
type IProcedur = {

    customer: {
        age: string,
        gender: string,
    }
    user: {
        firstName: string,
        lastName: string,
        age?: string,
        gender?: string,
        phoneNumber: string
    }
    product: {
        id: string
    }
    experience: number
    duration: number
    failed: string,
    url: string,
    own: boolean,
    createdAt: string
    followup: boolean,
    same: boolean
    procedureType: string,
    qr?: string,
    procedure: number,
    problem: number,
}

type DateType = {
    start: any,
    end?: any
    constant?: boolean
}
type Card = {
    total: number;
    day: number;
    duration: number;
    fail: number;
    passed: number

}

export const Statistics = React.memo(() => {
    const [next, State] = useDoctorWizard();
    // const [checked, setChecked] = React.useState({
    //     product1: false,
    //     product2: false
    // });
    const [card, setCard] = React.useState<Card>();
    const [isOpenaCal, setIsOpenCal] = React.useState<boolean>(false);
    const [tableData, setTableData] = React.useState<TableState[]>();
    const [date, setDate] = React.useState<DateType | undefined>({
        start: new Date().toLocaleDateString()
    });
    // const [statData, setStatData] = useState<Days>();

    const procedureDataProcessing = (data: IProcedur[]) => {

        const processed = data.map((item: IProcedur) => {
            return {
                createdAt: new Date(item.createdAt).toLocaleDateString(),
                product: item.product.id,
                qr: item.qr ? item.qr : 'false',
                name: `${item.user.firstName} ${item.user.lastName}`,
                age: item.customer.age,
                gender: item.customer.gender,
                phoneNumber: item.user.phoneNumber,
                own: `${item.own}`,
                url: item.url,
                failed: `${item.failed}`,
                duration: `${item.duration}`,
                experience: `${item.experience}`,
                procedure: `${item.procedure}`,
                problem: item.problem,
                followup: `${item.followup}`,
                same: `${item.same}`,
                procedureType: `${item.procedureType}`,
            }
        })
        setTableData(processed)

    }


    useEffect(() => {
        async function getData() {
            try {
                const result = await api.getApi(`${STATISTICS_PATH}?limit=100&sort={"createdAt":-1}&include=user,customer,product`);
                procedureDataProcessing(result.results);
            } catch (e) {
                console.log(e);
            }
        }

        getData();
    }, []);
    console.log(tableData)
    const handleDropdown = async (e: any) => {
        const startDate = new Date(e.start).toLocaleDateString();
        let endDate;
        if (e.end) {
            endDate = new Date(e.end).toLocaleDateString();
        }
        if (e.constant) {
            setDate({start: startDate, end: endDate});
        } else {
            if (endDate) {
                if (startDate > endDate) {
                    setDate({start: endDate, end: startDate});
                } else {
                    setDate({start: startDate, end: endDate});
                }
            } else {
                setDate({start: startDate});
            }
        }
        setIsOpenCal(false);
    };
    // const OpenModal = useCallback(() => {
    //     setIsOpenModal(true);
    // }, [isOpenModal]);

    // const CancelModal = () => {
    //     setIsOpenModal(false);
    // };
    //
    // const fetchData = async (isDate) => {
    //     try {
    //         const result = await api.getStatisticsData(isDate?.start, isDate?.end);
    //         setDate(isDate)
    //     } catch (error) {
    //         console.error("Error", error);
    //     }
    // };
    //
    // useEffect(() => {
    //     if (isDate) {
    //         fetchData(isDate);
    //     }
    // }, [isDate]);

    const openCal = useCallback(() => {
        setIsOpenCal(true);
    }, []);
    return (
        <div className={"ml-[288px] w-[85%] h-screen"}>
            <Header className={"pt-5 ml-5 "} text={"Products"}/>
            <div>
                <TableTest rows={[
                    {
                        registered: State?.subscription?.createdAt,
                        productType: State?.subscription?.productType,
                        subscription: State?.subscription?.subscription,
                        key: State?.key,
                        expired: State?.subscription?.expiredAt,
                        days: State?.subscription?.expiredDays,
                        firstName: State?.user?.firstName,
                        lastName: State?.user?.lastName,
                        phoneNumber: State?.user?.phoneNumber,
                        email: State?.user?.email
                    }
                ]}
                           columns={["Registered", "Product Type", "Subscription", "Key", "Expired", "Days", "First Name", "Last  Name", "Phone Number", "Email"]}
                           tdClass={'pr-2 text-start text-base'}
                           thClass={'text-start text-gray-light font-normal pr-2 '}/>
                {/*<Table*/}
                {/*  name={"communication"}*/}
                {/*  tableName={["Registered", "Product Type", "Subscription", "Key", "Expired", "Days", "First Name", "Last  Name", "Phone Number", "Email"]}*/}
                {/*  data={[*/}
                {/*    {*/}
                {/*      registered: State?.subscription?.createdAt,*/}
                {/*      productType: State?.subscription?.productType,*/}
                {/*      subscription: State?.subscription?.subscription,*/}
                {/*      key: State?.key,*/}
                {/*      expired: State?.subscription?.expiredAt,*/}
                {/*      days: State?.subscription?.expiredDays,*/}
                {/*      firstName: State?.user?.firstName,*/}
                {/*      lastName: State?.user?.lastName,*/}
                {/*      phoneNumber: State?.user?.phoneNumber,*/}
                {/*      email: State?.user?.email*/}
                {/*    }*/}
                {/*  ]}/>*/}
            </div>
            {/*<div className={"w-full p-5  flex justify-end"}>*/}
                {/*{isOpenModal ? <MyModal isOpen={isOpenModal} onCancel={CancelModal}/> : null}*/}
                {/*<label*/}
                {/*    className="cursor-pointer text-blue-primary px-4 py-2 rounded-full border-1 border-gray-light">*/}
                {/*    <span>Add New System</span>*/}
                {/*    <button onClick={OpenModal} className={"hidden"}/>*/}
                {/*</label>*/}
            {/*</div>*/}
            <div className={"w-full border-t-2 p-4 z-0"}>
                <div>
                    {/*<div className={'ml-5'}>*/}
                    {/*    <input type="checkbox" id="product1"/>*/}
                    {/*    <label className={'mx-3'}>Product 1</label>*/}

                    {/*    <input className={'mr-3'} type="checkbox" id="product2"/>*/}
                    {/*    <label>Product 2</label>*/}
                    {/*</div>*/}
                </div>
                <div className={"flex justify-between items-center pt-5 pr-4 max-lg:flex max-lg:justify-end"}>
                    <p className={" ml-5 max-lg:hidden"}>Statistics</p>

                    <div className={"flex justify-end "}>

                        {isOpenaCal &&
                            <DateRangePicker cancelCal={() => setIsOpenCal(false)} handleSubmit={handleDropdown}/>}
                        {(!isOpenaCal && date) ? (<div onClick={() => openCal()}
                                                       className={" shadow-2xl cursor-pointer"}>{date.start.replace(/(\d+)\/(\d+)\/(\d+)/, "$2.$1.$3")}{date.end?.replace(/(\d+)\/(\d+)\/(\d+)/, "$2.$1.$3") ? ` - ${date.end?.replace(/(\d+)\/(\d+)\/(\d+)/, "$2.$1.$3")}` : ""}</div>) :
                            (<div onClick={() => openCal()} className={" shadow-2xl cursor-pointer "}>
                                <CalendarMonthIcon sx={{color: "#5000FF"}}/>
                            </div>)}

                    </div>
                </div>
                <div className={"flex gap-9 max-xl:flex-col"}>
                    <div className={"flex flex-col gap-9 h-1/2 w-1/2 mt-4 ml-5 max-xl:order-2"}>
                        <div className={"flex gap-9  xl:flex-row max-lg:flex-col"}>
                            <StatCard number={card ? card.total : 0} text={"Total procedures"}/>
                            <StatCard number={(card && !isNaN(card.day)) ? card.day : 0} text={"Average per day"}/>
                            <StatCard number={(card && !isNaN(card.duration)) ? card.duration : 0}
                                      text={"Average duration"}/>
                        </div>

                        <div className={"flex gap-9   xl:flex-row max-lg:flex-col"}>
                            <StatCard number={(card && !isNaN(card.passed)) ? card.passed : 0} text={"Passed"}/>
                            <StatCard number={(card && !isNaN(card.fail)) ? card.fail : 0} text={"Failed"}/>
                            <div className={"w-1/3"}></div>
                        </div>

                    </div>
                    <div className={"flex flex-col w-1/2 max-xl:order-1 max-xl:w-3/4"}>
                        <BasicArea date={date} setStatistics={setCard}/>
                        <div className={"flex justify-center gap-9  "}>
                            <div className={"flex items-center gap-3 max-lg:flex-col"}>
                                <div className={"w-7 h-5 rounded-lg bg-gray-middleDoctor"}></div>
                                Failed
                            </div>
                            <div className={"flex items-center gap-3 max-lg:flex-col "}>
                                <div className={"w-7 h-5 rounded-lg bg-gray-lighterDoctor"}></div>
                                Passed
                            </div>
                            <div className={"flex items-center gap-3 max-lg:flex-col"}>
                                <div className={"w-7 h-5 rounded-lg bg-blue-primary"}></div>
                                Total
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"h-[300px] mt-5 w-full overflow-y-scroll"}>
                    {tableData && <TableTest rows={tableData} columns={['Date created', 'Product', 'QR Scan', 'Name', 'Age','Gender','Phone','Own','Content','Failed','Duration','Experience','Procedure','Problem','Followup','Same','Procedure Type']}
                                    tdClass={"p-2 text-center truncate max-w-14"}
                                    thClass={"text-gray-light font-normal text-center max-w-14 pr-2"}
                                    edit={true}/>}
                    {/*<Table staData={tableData} name={"star"}/>*/}

                </div>
            </div>
        </div>
    );
});
